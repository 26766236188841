export const AUTH = '/login';
export const MAIN = '/main';
export const PRODUCT_INFO = '/product/:productId/info';
export const ORDER = '/orders/:orderId';
export const LOUNGES = '/lounges';
export const LOUNGES_FILTER = '/lounges/filter';
export const LOUNGE_ID = '/lounges/:id';
export const LOUNGE_ORDER = '/lounges/:id/order';
export const PAYMENT_METHOD = '/lounges/:id/order/payment';
export const PROFILE = '/profile';
export const PROFILE_EDIT = '/profile/edit';
export const PROFILE_EDIT_EMAIL = '/profile/edit/email';
export const PROFILE_EDIT_PHONE = '/profile/edit/phone';
export const SUPPORT = '/support';
export const ABOUT = '/about';
