import { Link } from 'react-router-dom';
import { CityModel, LoungesModel } from '../../../models';
import { LOUNGES } from '../../../utils/config';
import styles from './LoungeRow.module.css';
import { useAppSelector } from '../../../utils/hooks';
import { selectLanguage } from '../../../redux/profileDataSlice';

interface Interface {
  data: LoungesModel;
  cities: CityModel;
  width: number;
}

const LoungeRow = (props: Interface) => {
  const { data, cities, width } = props;
  const language = useAppSelector(selectLanguage);

  const cityName = cities[data.cityCode] ?? data.cityCode;
  const name = `${cityName}${data.airportName ? ` ${data.airportName}` : ''}${
    data.iataCode ? `, ${data.iataCode}` : ''
  }`;

  return (
    <div className={styles.root}>
      <Link to={`${LOUNGES}/${data.id}`} className={styles.link} />
      <div className={styles.image_container}>
        {data.thumbnail ? (
          <div className={styles.image}>
            <img
              src={`/files${data.thumbnail}`}
              style={{
                ...(width ? { height: `calc(${width}px / 1.44)` } : {}),
              }}
              alt=""
            />
          </div>
        ) : (
          <div className={styles.thumbnail}></div>
        )}
        {data.workTime || data.loungeState === 'not_work' ? (
          <div className={styles.work_time}>
            {data.loungeState === 'not_work' ? language['LOUNGE_MAINTENANCE'] : data.workTime}
          </div>
        ) : null}
      </div>
      <div className={styles.lounge}>{name}</div>
      <div className={styles.terminal_row}>{data.terminalName}</div>
      <div className={styles.terminal_row}>{data.loungeName}</div>
    </div>
  );
};

export default LoungeRow;
