import React from 'react';
import { ViewProfile } from '../../components';
import { useAppSelector } from '../../utils/hooks';
import { selectProfile } from '../../redux/profileDataSlice';

const ProfileContainer = () => {
  const profile = useAppSelector(selectProfile);

  return <ViewProfile profile={profile} />;
};

export default ProfileContainer;
