import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Skeleton } from '@mui/material';
import { AddRounded, InfoOutlined } from '@mui/icons-material';
import { format } from 'date-fns';
import {
  loadCardsAsync,
  loadOrdersAsync,
  selectCards,
  selectCardsError,
  selectCardsLoading,
  selectOrders,
} from '../../redux/cardDataSlice';
import { selectLanguage, selectProfile } from '../../redux/profileDataSlice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import styles from './MainContainer.module.css';
import { BaseHeader } from '../../ui';
import { ProductCard, ProductCardAppearance } from '../../components';
import { CardModel } from '../../models';
import ActivityList from '../../components/ActivityList/ActivityList';
import ActivityListItem from '../../components/ActivityList/ActivityListItem';
import { ORDER, PRODUCT_INFO } from '../../utils/config';
import AddCardModal from '../../components/Main/AddCardModal';
import { Order, OrderState } from '../../redux/loungeOrderPageSlice';
import BaseHeaderButton from '../../ui/BaseHeaderButton';
import { setSelectedProduct, selectProduct } from '../../redux/productDataSlice';

const MainContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const selectedProduct = useAppSelector(selectProduct);
  const cards = useAppSelector(selectCards);
  const cardsError = useAppSelector(selectCardsError);
  const loading = useAppSelector(selectCardsLoading);
  const profile = useAppSelector(selectProfile);
  const language = useAppSelector(selectLanguage);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [isAddCardVisible, setIsAddCardVisible] = useState(false);

  const isActiveOrder = (order: Order) =>
    order.orderState === OrderState.Paid || order.orderState === OrderState.NoPaymentRequired;

  const compareOrderDates = (a: Order, b: Order) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    if (dateA > dateB) return -1;
    if (dateA < dateB) return 1;
    return 0;
  };

  const compareOrderState = (a: Order, b: Order) => {
    const aIsActive = isActiveOrder(a);
    const bIsActive = isActiveOrder(b);
    if (aIsActive && !bIsActive) return -1;
    if (!aIsActive && bIsActive) return 1;
    return 0;
  };

  const orders = useAppSelector((state) =>
    selectOrders(state)
      .filter((order) => (selectedProduct ? order.productUuid === selectedProduct.productId : true))
      .sort(compareOrderDates)
      .sort(compareOrderState)
  );

  useEffect(() => {
    loadOrders();
    !loading && dispatch(loadCardsAsync(profile?.language));
  }, []);

  const handleAddCardClick = () => {
    setIsAddCardVisible(true);
  };

  const loadOrders = async (productId?: string) => {
    setLoadingActivity(true);
    await dispatch(loadOrdersAsync());
    setLoadingActivity(false);
  };

  const handleCardClick = async (product: CardModel) => {
    if (cards.length === 1) {
      return;
    }
    if (product.productId === selectedProduct?.productId) {
      dispatch(setSelectedProduct(null));
    } else {
      dispatch(setSelectedProduct(product));
    }
  };

  const handleInfoClick = () => {
    if (selectedProduct || cards.length === 1) {
      history.push(
        PRODUCT_INFO.replace(':productId', selectedProduct?.productId || cards[0].productId)
      );
    }
  };

  const handleOrderClick = (orderId: number) => {
    history.push(ORDER.replace(':orderId', String(orderId)));
  };

  return (
    <div>
      <BaseHeader
        solidBackground
        withLogo
        titleSmall
        actions={
          <>
            {!selectedProduct && (
              <BaseHeaderButton icon={<AddRounded />} iconTinted onClick={handleAddCardClick}>
                {language['ADD_BANKID']}
              </BaseHeaderButton>
            )}
            {(selectedProduct || cards.length === 1) && (
              <BaseHeaderButton
                type="circle"
                // visible={!!(selectedProduct?.rules || selectedProduct?.description)}
                onClick={handleInfoClick}>
                <InfoOutlined />
              </BaseHeaderButton>
            )}
          </>
        }
      />
      <div className={styles.cardsColumn}>
        {loading ? (
          <Skeleton height={230} variant="rounded" />
        ) : cardsError ? (
          <Alert variant="outlined" severity="error">
            {cardsError}
          </Alert>
        ) : cards.length ? (
          cards.map((product, i) => (
            <div
              key={product.cardId}
              className={
                selectedProduct?.productId === product?.productId
                  ? styles.topCard
                  : selectedProduct === null
                  ? ''
                  : styles.hiddenCard
              }
              style={{ top: `${i * 80}px` }}>
              <ProductCard
                key={product.productId}
                product={product}
                appearance={
                  product.bankId === 'c1edeeda-fd48-480b-a501-0891a0ff417d'
                    ? ProductCardAppearance.Tinkoff
                    : ProductCardAppearance.GreyWallPass
                }
                showGreyWallPassLogo
                onClick={() => handleCardClick(product)}
              />
            </div>
          ))
        ) : (
          <Alert variant="outlined" severity="warning">
            {language['DONT_HAVE_PROD']}
          </Alert>
        )}
        <div
          className={styles.activityContainer}
          style={{
            top: `${220 + 6 + (selectedProduct ? 0 : (cards.length - 1) * 80)}px`,
            opacity: cards?.length && !loadingActivity ? 1 : 0,
          }}>
          <ActivityList label={language['ACTIVITY']}>
            {orders.map((order) => (
              <ActivityListItem
                key={order.orderId}
                title={order.loungeName}
                passesCount={order.adults}
                amount={order.amount}
                date={order.createdAt ? format(new Date(order.createdAt), 'dd.MM.yyyy HH:mm') : ''}
                orderState={order.orderState}
                icon={order.iataCode === 'RZD' ? 'train' : 'plane'}
                floating={isActiveOrder(order)}
                onClick={() => handleOrderClick(order.orderId)}
              />
            ))}
          </ActivityList>
        </div>
        <AddCardModal visible={isAddCardVisible} onClose={() => setIsAddCardVisible(false)} />
      </div>
    </div>
  );
};

export default MainContainer;
