import { FC, ReactNode } from 'react';
import styles from './BaseHeaderButton.module.css';

interface BaseHeaderButtonProps {
  type?: 'default' | 'circle';
  floating?: boolean;
  icon?: ReactNode;
  iconTinted?: boolean;
  showDot?: boolean;
  visible?: boolean;
  children?: ReactNode;
  onClick?: () => void;
}

const BaseHeaderButton: FC<BaseHeaderButtonProps> = ({
  type,
  floating,
  iconTinted,
  icon,
  showDot,
  visible,
  children,
  onClick,
}) => {
  return (
    <div
      className={`${type === 'circle' ? styles.circleButton : styles.defaultButton} ${
        floating ? styles.floating : ''
      }`}
      style={{ opacity: visible === undefined || visible ? 1 : 0 }}
      onClick={onClick}>
      {showDot && <div className={styles.dot}></div>}
      {children}
      {icon && <div className={iconTinted ? styles.iconTinted : ''}>{icon}</div>}
    </div>
  );
};

export default BaseHeaderButton;
