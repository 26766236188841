import { FC } from 'react';
import { Add, Remove } from '@mui/icons-material';
import styles from './BaseCounter.module.css';

interface BaseCounterProps {
  min?: number;
  max?: number;
  value?: number;
  onChange?: (value: number) => void;
}

const BaseCounter: FC<BaseCounterProps> = ({ min, max, value = 0, onChange }) => {
  const allowDecrease = min !== undefined ? value - 1 >= min : true;
  const allowIncrease = max !== undefined ? value + 1 <= max : true;

  const decreaseStyles = allowDecrease
    ? styles.button
    : `${styles.button} ${styles.buttonDisabled}`;
  const increaseStyles = allowIncrease
    ? styles.button
    : `${styles.button} ${styles.buttonDisabled}`;

  return (
    <div className={styles.controls}>
      <div className={decreaseStyles} onClick={() => allowDecrease && onChange?.(value - 1)}>
        <Remove />
      </div>
      <span className={styles.count}>{value}</span>
      <div className={increaseStyles} onClick={() => allowIncrease && onChange?.(value + 1)}>
        <Add />
      </div>
    </div>
  );
};

export default BaseCounter;
