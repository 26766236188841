import { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowBackIosRounded } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import styles from './BaseHeader.module.css';
import { useAppSelector } from '../../utils/hooks';
import { selectLanguage } from '../../redux/profileDataSlice';
import logo from '../../static/logo_mini.svg';
import BaseHeaderButton from '../BaseHeaderButton';

interface BaseHeaderProps {
  title?: string;
  titleSmall?: boolean;
  subtitle?: string;
  actions?: ReactNode;
  showBack?: boolean;
  children?: ReactNode;
  loading?: boolean;
  withLogo?: boolean;
  solidBackground?: boolean;
  onTitleClick?: () => void;
  onActionClick?: () => void;
  onBack?: () => void;
}

const BaseHeader: FC<BaseHeaderProps> = ({
  title,
  titleSmall,
  subtitle,
  actions,
  showBack,
  children,
  loading,
  solidBackground,
  withLogo,
  onTitleClick,
  onActionClick,
  onBack,
}) => {
  const history = useHistory();
  const language = useAppSelector(selectLanguage);

  return (
    <div
      className={styles.header}
      style={{
        paddingBottom: titleSmall ? 2 : undefined,
        background: solidBackground ? 'white' : undefined,
        borderBottom: solidBackground ? 'none' : undefined,
      }}>
      <div className={styles.toolbar}>
        {showBack ? (
          <>
            <BaseHeaderButton type="circle" onClick={onBack || history.goBack}>
              <ArrowBackIosRounded style={{ height: 18 }} />
            </BaseHeaderButton>
          </>
        ) : (
          <div>{withLogo ? <img src={logo} className={styles.logo} alt="" /> : <></>}</div>
        )}
        {titleSmall && (
          <div className={styles.titleSmall} onClick={onTitleClick}>
            {title}
          </div>
        )}
        {actions ? (
          <div className={styles.actions} onClick={onActionClick}>
            {actions}
          </div>
        ) : (
          <div></div>
        )}
      </div>
      {!titleSmall && (
        <div className={styles.title} onClick={onTitleClick}>
          {loading ? <Skeleton variant="rounded" height={36} width="60%" /> : title}
        </div>
      )}
      {subtitle && (
        <div className={styles.subtitle}>
          {loading ? <Skeleton variant="rounded" height={20} width="80%" /> : subtitle}
        </div>
      )}
      {children}
    </div>
  );
};

export default BaseHeader;
