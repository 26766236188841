import { FC, useEffect, useState } from 'react';
import { SearchRounded } from '@mui/icons-material';
import styles from './SearchBar.module.css';
import { BaseInput } from '../../ui';
import BaseHeaderButton from '../../ui/BaseHeaderButton';
import { useAppSelector } from '../../utils/hooks';
import { selectLanguage } from '../../redux/profileDataSlice';

interface SearchBarProps {
  visible?: boolean;
  onChange?: (query: string) => void;
  onCancel?: () => void;
}

const SearchBar: FC<SearchBarProps> = ({ visible, onChange, onCancel }) => {
  const language = useAppSelector(selectLanguage);
  const [inputVisible, setInputVisible] = useState(visible);

  useEffect(() => {
    if (visible === undefined) {
      return;
    }
    if (visible) {
      setInputVisible(true);
    }
    if (!visible) {
      setTimeout(() => setInputVisible(false), 300);
    }
  }, [visible]);

  return (
    <div
      className={`${styles.container} ${visible ? '' : styles.invisible}`}
      style={{ zIndex: visible ? 1 : -1 }}>
      {inputVisible && (
        <BaseInput
          autofocus
          placeholder={language['FIND_STRING']}
          startIcon={<SearchRounded />}
          onChange={onChange}
        />
      )}
      <BaseHeaderButton onClick={onCancel}>{language['CANCEL']}</BaseHeaderButton>
    </div>
  );
};

export default SearchBar;
