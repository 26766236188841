import { TokenResponse } from '../api';

const PROJECT_NAME = 'client';

const setLocalAuth = (data: TokenResponse) => {
  localStorage.setItem(`gwp_${PROJECT_NAME}_auth`, JSON.stringify(data));
  localStorage.setItem(
    `gwp_${PROJECT_NAME}_auth_expires`,
    JSON.stringify(new Date().getTime() + data.expires_in * 1000)
  );
  localStorage.setItem(
    `gwp_${PROJECT_NAME}_auth_refresh_expires`,
    JSON.stringify(new Date().getTime() + data.refresh_expires_in * 1000)
  );
};

const getLocalAuth = (): TokenResponse | null => {
  const authString = localStorage.getItem(`gwp_${PROJECT_NAME}_auth`);
  if (!authString || authString === 'undefined') {
    return null;
  }
  return JSON.parse(authString);
};

const removeLocalAuth = () => {
  localStorage.removeItem(`gwp_${PROJECT_NAME}_username`);
  localStorage.removeItem(`gwp_${PROJECT_NAME}_auth`);
  localStorage.removeItem(`gwp_${PROJECT_NAME}_auth_expires`);
  localStorage.removeItem(`gwp_${PROJECT_NAME}_auth_refresh_expires`);
};

const getAccessTokenExpires = () => {
  const expires = localStorage.getItem(`gwp_${PROJECT_NAME}_auth_expires`);
  return expires ? new Date(parseInt(expires)) : null;
};

const isAccessTokenExpired = (): boolean => {
  const expires = getAccessTokenExpires();
  return expires ? new Date() >= expires : true;
};

const parseJwt = <T = unknown>(jwtString: string): T | null => {
  try {
    return JSON.parse(atob(jwtString.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export {
  setLocalAuth,
  getLocalAuth,
  removeLocalAuth,
  getAccessTokenExpires,
  isAccessTokenExpired,
  parseJwt,
};
