import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ReactInputVerificationCode from 'react-input-verification-code';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { selectLanguage } from '../../../redux/profileDataSlice';
import {
  checkCodeAsync,
  selectCodeId,
  selectLoadingCheckCode,
  selectResetPassword,
  sendCodeAsync,
  setResetPassword,
} from '../../../redux/registrationDataSlice';
import styles from './Verification.module.css';
import './Verification.css';

interface Interface {
  onSelectAuth: (type: string) => void;
}

const Verification = ({ onSelectAuth }: Interface) => {
  const dispatch = useAppDispatch();
  const SECONDS = 35;

  const language = useAppSelector(selectLanguage);
  const codeId = useAppSelector(selectCodeId);
  const loadingCheckCode = useAppSelector(selectLoadingCheckCode);
  const resetPassword = useAppSelector(selectResetPassword);

  const [value, setValue] = useState('');
  const [counter, setCounter] = useState(SECONDS);
  const [isCorrectCode, setIsCorrectCode] = useState<boolean | undefined>();

  useEffect(() => {
    const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleChangeCode = (code: string) => {
    isCorrectCode === false && setIsCorrectCode(undefined);
    setValue(code);
  };

  const handleCheckCode = async () => {
    const phone = window.localStorage.getItem('gwp_username') || '';
    const response = await dispatch(checkCodeAsync(phone, value, codeId));

    setIsCorrectCode(response === 'isCorrect');
    response === 'isCorrect' && onSelectAuth(resetPassword ? 'password' : 'registration');
  };

  const handleRefreshCode = async () => {
    if (counter === 0) {
      const phone = window.localStorage.getItem('gwp_username') || '';
      dispatch(sendCodeAsync(phone));
      isCorrectCode === false && setIsCorrectCode(undefined);
      setIsCorrectCode(undefined);
      setValue('');
      setCounter(SECONDS);
    }
  };

  const handleBack = () => {
    onSelectAuth('manual');
    resetPassword && dispatch(setResetPassword(false));
  };

  return (
    <div className={styles.root}>
      <div>
        <div onClick={handleBack} className={styles.back}>
          <ArrowBackIosIcon /> {language['GOBACK_LINK']}
        </div>
        <div className={styles.title}>{language['CONF_COD']}</div>
        <div className={styles.text}>{language['ENT_CONF_CODE']}</div>
        <div className="verification">
          <ReactInputVerificationCode
            autoFocus
            placeholder=""
            value={value}
            onChange={handleChangeCode}
          />
        </div>
        {isCorrectCode === false ? (
          <Alert variant="outlined" severity="warning">
            {language['INCORRECT_CODE']}
          </Alert>
        ) : null}
      </div>
      <div style={{ marginBottom: 10 }}>
        <div
          className={`${styles.timer} ${counter === 0 ? styles.timer_active : ''}`}
          onClick={handleRefreshCode}>
          {counter
                      ? `${language['REQ_NEW_CODE']} 00:${counter < 10 ? `0${counter}` : counter}`
                      : 'Запросить код повторно'}
        </div>
        <LoadingButton
          loading={loadingCheckCode}
          variant="contained"
          classes={{ root: styles.button }}
          disabled={!value || value.length < 4 || isCorrectCode === false}
          onClick={handleCheckCode}
          fullWidth>
          {language['GO_BUTTON_1']}
        </LoadingButton>
      </div>
    </div>
  );
};

export default Verification;
