import { FC } from 'react';
import { PeopleOutline } from '@mui/icons-material';
import { formatCurrency } from '../../utils/currency';
import styles from './ActivityListItem.module.css';
import { selectOrderState, OrderState } from '../../redux/loungeOrderPageSlice';
import airplane from '../../static/airplane.svg';
import railway from '../../static/railway.svg';
import { useAppSelector } from '../../utils/hooks';

interface ActivityListItemProps {
  title?: string;
  date?: string;
  passesCount?: string | number;
  amount?: number;
  orderState?: OrderState;
  icon?: 'plane' | 'train';
  floating?: boolean;
  onClick?: () => void;
}

const ActivityListItem: FC<ActivityListItemProps> = ({
  title,
  date,
  passesCount,
  amount,
  orderState,
  icon,
  floating,
  onClick,
}) => {
  const orderStateEnum = useAppSelector(selectOrderState);

  const showStatusDot = () => {
    switch (orderState) {
      case OrderState.Paid:
      case OrderState.NoPaymentRequired:
      case OrderState.Unpaid:
        return true;
      default:
        return false;
    }
  };

  const getStatusColor = () => {
    switch (orderState) {
      case OrderState.Paid:
      case OrderState.NoPaymentRequired:
        return '#1ba049';
      case OrderState.Unpaid:
        return '#D92020';
      default:
        return 'black';
    }
  };

  const getStatusLabel = () => {
    if (!orderStateEnum || !orderState) {
      return '';
    }
    return orderStateEnum[orderState];
  };

  return (
    <div
      className={[styles.container, floating ? styles.floating : ''].join(' ')}
      onClick={onClick}>
      <div className={styles.main}>
        <div className={styles.imageContainer}>
          {icon && <img src={icon === 'train' ? railway : airplane} alt="" />}
          {showStatusDot() && (
            <div className={styles.statusDot} style={{ backgroundColor: getStatusColor() }} />
          )}
        </div>
        <div className={styles.details}>
          <div className={styles.title}>{title}</div>
          <div className={styles.meta}>
            <div>{date}</div>
            <span>•</span>
            <div>
              <PeopleOutline fontSize="small" />
              {passesCount}
            </div>
          </div>
          {getStatusLabel() && (
            <div className={styles.status} style={{ color: getStatusColor() }}>
              {getStatusLabel()}
            </div>
          )}
        </div>
      </div>
      {amount !== undefined && <div className={styles.price}>{formatCurrency(amount)}</div>}
    </div>
  );
};

export default ActivityListItem;
