import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppDispatch, RootState } from '../redux/store';
import httpClient from '../api/http-client';
import { setToken } from '../redux/profileDataSlice';
import { getLocalAuth } from './auth';
import { AUTH } from './config';
import { clearAuthCookies } from './CookieUtils';
import { revokeToken } from '../api';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const loadAuth = () => {
    const auth = getLocalAuth();
    if (auth) {
      httpClient.defaults.headers.common[
        'Authorization'
      ] = `${auth.token_type} ${auth.access_token}`;
    }
    dispatch(setToken(auth));

    return auth;
  };

  const logout = () => {
    clearAuthCookies();
    dispatch(setToken(null));
    revokeToken(false);
    history.push(AUTH);
  };

  return {
    loadAuth,
    logout,
  };
};
