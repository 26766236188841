import React, { ChangeEvent, CSSProperties } from 'react';
import { InputBase } from '@mui/material';
import styles from './Input.module.css';

interface Interface {
  autoFocus?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  error?: boolean;
  onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  placeholder: string;
  value: string;
  style?: CSSProperties;
}

const Input = (props: Interface) => {
  const {
    autoFocus,
    fullWidth = true,
    disabled,
    error,
    onChange,
    placeholder,
    value,
    style,
  } = props;

  return (
    <InputBase
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      className={styles.input}
      style={style}
      disabled={disabled}
      error={error}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    />
  );
};

export default Input;
