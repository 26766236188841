import React, { useState } from 'react';
import {
  LoginAuth,
  LoginHello,
  LoginPassword,
  ManualLogin,
  Registration,
  Verification,
} from '../../components';
import styles from './LoginContainer.module.css';

const AuthContainer = () => {
  const [authType, setAuthType] = useState<string>('');
  const handleAuthType = (type: string) => setAuthType(type);

  const getLayout = () => {
    switch (authType) {
      case 'manual':
        return <ManualLogin onSelectAuth={handleAuthType} />;
      case 'verification':
        return <Verification onSelectAuth={handleAuthType} />;
      case 'registration':
        return <Registration onSelectAuth={handleAuthType} />;
      case 'password':
        return <LoginPassword onSelectAuth={handleAuthType} />;
      case 'auth':
        return <LoginAuth onSelectAuth={handleAuthType} />;
      default:
        return <LoginHello onSelectAuth={handleAuthType} />;
    }
  };

  return <div className={styles.root}>{getLayout()}</div>;
};

export default AuthContainer;
