import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig } from 'axios';
import { getLocalAuth, isAccessTokenExpired } from '../utils/auth';
import { refreshToken, revokeToken } from './endpoints/auth';

const BASE_API_URL = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_ROUTE || ''}`;

const localAuth = getLocalAuth();

const httpClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    common: {
      Authorization: localAuth ? `${localAuth.token_type} ${localAuth.access_token}` : '',
    },
  },
});

const setAuthHeader = (tokenType: string, accessToken: string, config?: AxiosRequestConfig) => {
  const headerName = 'Authorization';
  const headerValue = `${tokenType} ${accessToken}`;
  if (config) {
    config.headers = { ...config.headers } as AxiosHeaders; // https://github.com/axios/axios/issues/5416
    config.headers.set(headerName, headerValue);
  }
  if (httpClient.defaults.headers.common[headerName] !== headerValue) {
    httpClient.defaults.headers.common[headerName] = headerValue;
  }
};

httpClient.interceptors.request.use(async (config) => {
  if (isAccessTokenExpired()) {
    const response = await refreshToken();
    if (response) {
      setAuthHeader(response.token_type, response.access_token, config);
    }
  }
  return config;
});

httpClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response?.status === 502) {
      alert(
        'В данный момент происходит обновление сервера, обновите страницу через некоторое время'
      );
    }
    if (error.response?.status && [401].includes(error.response.status)) {
      const authData = getLocalAuth();
      const { config } = error;
      if (config && authData) {
        try {
          const response = await refreshToken();
          if (response) {
            setAuthHeader(response.token_type, response.access_token, config);
          }
          return httpClient(config);
        } catch (_error) {
          revokeToken();
        }
      }
    }
    return Promise.reject(error);
  }
);

export { BASE_API_URL, setAuthHeader };
export default httpClient;
