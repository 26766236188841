import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import { Input } from '../../index';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { selectLanguage } from '../../../redux/profileDataSlice';
import { addProductAsync } from '../../../redux/productDataSlice';
import BaseButton from '../../../ui/BaseButton';
import BaseBottomSheet from '../../../ui/BaseBottomSheet';

interface AddCardModalProps {
  visible?: boolean;
  onClose: () => void;
}

const AddCardModal: FC<AddCardModalProps> = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);

  const [card, setCard] = useState<any | null>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const container = document.getElementById('container');

    if (container) {
      container.style.overflow = 'hidden';
    }

    return () => {
      if (container) {
        container.style.overflow = 'auto';
      }
    };
  }, []);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const cardValue = event.target.value
      .replace(/\D/g, '')
      .match(/.{1,4}/g);
    cardValue && setCard(cardValue.join(' '));
  };

  const handleSave = async () => {
    const numbers = card.replace(/(\D)/g, '');
    const response = await dispatch(addProductAsync(numbers));
    if (response) {
      onClose();
    } else {
      setError('При добавление карты банка произошла ошибка');
    }
  };

  return (
    <BaseBottomSheet
      visible={visible}
      title={language['ADD_BANKID']}
      actionButtons={
        <BaseButton onClick={handleSave} disabled={card.length < 14}>
          {language['SAVE_BUTTON']}
        </BaseButton>
      }
      onClose={onClose}>
      <>
        <Input
          error={!card}
          onChange={handleChange}
          placeholder={language['ENTER_CARDNUMBER']}
          value={card}
        />
        {error.length ? (
          <Alert variant="outlined" severity="warning">
            {error}
          </Alert>
        ) : null}
      </>
    </BaseBottomSheet>
  );
};

export default AddCardModal;
