import { createSlice } from '@reduxjs/toolkit';
import httpClient from '../api/http-client';
import { AppDispatch, RootState } from './store';
import { CardModel } from '../models';

interface InitialState {
  product: CardModel | null;
}

export const productDataSlice = createSlice({
  name: 'productData',
  initialState: {
    product: null,
  } as InitialState,
  reducers: {
    setSelectedProduct: (state, action) => {
      state.product = action.payload;
    },
  },
});

export const { setSelectedProduct } = productDataSlice.actions;

export const addProductAsync = (cardNumber: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await httpClient.post(`user/product/add`, { cardNumber });
    if (response.status === 200) {
      return true;
    }
  } catch (e: any) {
    console.log('addProductAsync', e.message);
    return false;
  } finally {
  }
};

export const selectProduct = (state: RootState) => state.productData.product;
export default productDataSlice.reducer;
