import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ArrowForwardIos, Language } from '@mui/icons-material';
import { selectLanguage, updateUserProfileAsync } from '../../../redux/profileDataSlice';
import { resetLoungeFilterSortOrder } from '../../../redux/loungePageSlice';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { UserProfileModel } from '../../../models';
import styles from './LanguageSelector.module.css';

interface Interface {
  profile: UserProfileModel;
}

const LanguageSelector = (props: Interface) => {
  const { profile } = props;

  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);

  const languages = [
    { value: 'ru', name: 'Русский' },
    { value: 'en', name: 'English' },
  ];
  const [lang, setLang] = useState(
    languages.find((_) => _.value === profile.language) || languages[0]
  );

  const [viewDialog, setViewDialog] = useState(false);
  const handleToggleViewDialog = () => setViewDialog(!viewDialog);

  const handleSave = async () => {
    const success = await dispatch(
      updateUserProfileAsync({
        userGuid: profile.userUuid,
        firstname: profile.firstname,
        surname: profile.surname,
        patronymic: profile.patronymic,
        latinFullname: profile.latinFullname,
        latinFirstname: profile.latinFirstname,
        latinSurname: profile.latinSurname,
        latinPatronymic: profile.latinPatronymic,
        language: lang.value,
      })
    );
    setViewDialog(!viewDialog);
    if (success) {
      dispatch(resetLoungeFilterSortOrder());
    } else {
      setLang(languages.find((_) => _.value === profile.language) || languages[0]);
      await new Promise<void>((resolve) => setTimeout(resolve, 10));
      alert('Не удалось обновить данные профиля');
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const selected = languages.find((_) => _.value === event.target.value) || languages[0];
    setLang(selected);
  };

  return (
    <>
      <Button
        variant="text"
        fullWidth
        color="inherit"
        onClick={handleToggleViewDialog}
        style={{ height: 48 }}>
        <div className={styles.button_inner}>
          <Language className={styles.button_icon} />
          <div className={styles.button_content}>
            <div className={styles.button_text}>{language['LANG']}</div>
            <div className={styles.button_language_container}>
              <div className={styles.button_language}>{lang.name}</div>
              <ArrowForwardIos className={styles.button_arrow} />
            </div>
          </div>
        </div>
      </Button>
      {viewDialog ? (
        <Dialog open={true} onClose={handleToggleViewDialog}>
          <DialogTitle>{language['SELECT_LANGUAGE']}</DialogTitle>
          <DialogContent className={styles.dialog_content}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{language['LANG']}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={lang.value}
                label="Язык"
                onChange={handleChange}>
                {languages.map((_) => (
                  <MenuItem key={_.value} value={_.value}>
                    {_.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleToggleViewDialog}>{language['CANCEL']}</Button>
            <Button onClick={handleSave} color="primary">
              {language['SELECT']}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default LanguageSelector;
