import React, { useState } from 'react';
import { Alert } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LoadingButton from '@mui/lab/LoadingButton';
import PhoneInput from 'react-phone-input-2';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { PhoneState } from '../../../utils/Constants';
import { checkUserPhoneAsync, selectLanguage, selectLoadingCheckPhone } from '../../../redux/profileDataSlice';
import { selectLoadingSendCode, sendCodeAsync } from '../../../redux/registrationDataSlice';
import 'react-phone-input-2/lib/style.css';
import styles from './ManualLogin.module.css';

interface Interface {
  onSelectAuth: (type: string) => void;
}

const ManualLogin = ({ onSelectAuth }: Interface) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const loadingCheckPhone = useAppSelector(selectLoadingCheckPhone);
  const loadingSendCode = useAppSelector(selectLoadingSendCode);

  const [phone, setPhone] = useState(window.localStorage.getItem('gwp_username') || '');
  const [disableBtn, setDisableBtn] = useState(!phone);
  const [error, setError] = useState('');

  const handleChange = (value: string, country: any) => {
    setPhone(value);
    setDisableBtn(value.length !== country.format.match(/\./g).length);
  };

  const handleValidPhone = (value: string, country: any) =>
    value.length === country.format.match(/\./g).length;

  const handleCheckNumber = async () => {
    const userState = await dispatch(checkUserPhoneAsync(phone));
    if (userState === PhoneState.ERROR) {
      setError('При проверке номера телефона возникла ошибка, повторите попытку позже');
    } else {
      setError('');
      if (userState === PhoneState.REGISTERED) {
        onSelectAuth('auth');
      } else {
        await dispatch(sendCodeAsync(phone));
        onSelectAuth('verification');
      }
    }

    window.localStorage.setItem('gwp_username', phone);
  };

  return (
    <div className={styles.root}>
      <div>
        <div onClick={() => onSelectAuth('')} className={styles.back}>
          <ArrowBackIosIcon /> {language['GOBACK_LINK']}
        </div>
        <div className={styles.title}>{language['ENT_PH_NUM']}</div>
        <div className={styles.text}>{language['ENT_PH_NUM_LOG']}</div>
        <PhoneInput
          value={phone}
          country={'ru'}
          isValid={handleValidPhone}
          containerClass={styles.container}
          inputClass={styles.input}
          buttonClass={styles.dropdown}
          placeholder={'7 (987) 654 3210'}
          prefix={''}
          inputProps={{ name: 'phone', required: true, autoFocus: true }}
          onChange={handleChange}
          onKeyDown={(e) => e.key === 'Enter' && handleCheckNumber()}
        />
        {error ? (
          <Alert variant="outlined" severity="error" sx={{marginTop: 2}}>
            {error}
          </Alert>
        ) : null}
      </div>
      <div style={{ marginBottom: 10 }}>
        <LoadingButton
          loading={loadingCheckPhone || loadingSendCode}
          variant="contained"
          classes={{ root: styles.button }}
          disabled={disableBtn}
          onClick={handleCheckNumber}
          fullWidth>
          {language['GO_BUTTON_1']}
        </LoadingButton>
      </div>
    </div>
  );
};

export default ManualLogin;
