import { FC } from 'react';
import { useAppSelector } from '../../utils/hooks';
import { selectLanguage } from '../../redux/profileDataSlice';
import styles from './PaymentCard.module.css';
import bankTinkoff from '../../static/bank_tinkoff.svg';
import bankSber from '../../static/bank_sber.svg';
import bankKhlynov from '../../static/bank_khlynov.svg';
import bankMkb from '../../static/bank_mkb.svg';
import bankBks from '../../static/bank_bks.svg';
import paymentSbp from '../../static/payment_sbp.svg';
import tinkoffPay from '../../static/tinkoff_pay.png';
import gwpLogo from '../../static/logo_mini.svg';
import tinkoffCassa from '../../static/tinkoff_cassa.jpg';
import BaseCard from '../../ui/BaseCard';
import { PaymentType } from '../../redux/loungeOrderPageSlice';

interface PaymentCardProps {
  bankId?: string;
  type?: PaymentType;
  amount?: string;
  prepaid?: boolean;
  description: string;
  onClick?: () => void;
}

const PaymentCard: FC<PaymentCardProps> = ({
  bankId,
  type,
  amount,
  prepaid,
  description,
  onClick,
}) => {
  const language = useAppSelector(selectLanguage);

  const getBankLogo = () => {
    switch (bankId) {
      case 'c1edeeda-fd48-480b-a501-0891a0ff417d':
        return bankTinkoff;
      case 'cc1f3772-27be-4874-9963-3749ef8cb1b5':
        return bankSber;
      case '22832e99-6771-4149-b36f-b18e117493c7':
        return bankMkb;
      case '5da5b07b-faf9-4ae5-9dcc-0eec2336a384':
        return bankKhlynov;
      case '4e461c64-c402-4672-ad3f-92ac7e18dd08':
        return bankBks;
      case 'cf2985e8-5d90-4242-9791-db3ae97ebdf0':
        return gwpLogo;
      default:
        return;
    }
  };

  const getPaymentLogo = () => {
    switch (type) {
      case PaymentType.TinkoffPay:
        return tinkoffPay;
      case PaymentType.SBP:
        return paymentSbp;
      case PaymentType.BankWidget:
        return tinkoffCassa;
      default:
        return '';
    }
  };

  return (
    <BaseCard
      title={<img src={getBankLogo()} alt="" />}
      right={
        <div className={`${styles.price} ${prepaid ? '' : styles.priceGradient}`}>
          <img src={getPaymentLogo()} alt="" />
          {amount?.substring(0, 1) !== '0' ? amount : language.COST_NOT_SPECIFIED}
        </div>
      }
      tags={prepaid ? undefined : [language['NO_PAYMENT_REQUIRED']]}
      subtitle={description}
      onClick={onClick}
    />
  );
};

export default PaymentCard;
