import React, { useEffect, useState } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { selectLanguage, selectProfile } from '../../../redux/profileDataSlice';
import {
  checkCodeAsync,
  selectCodeId,
  sendCodeAsync,
  setCodeId,
} from '../../../redux/registrationDataSlice';
import { createUserPhoneAsync, updateUserPhoneAsync } from '../../../redux/userPhoneDataSlice';
import { createUserEmailAsync, updateUserEmailAsync } from '../../../redux/userEmailDataSlice';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import styles from './VerificationModal.module.css';

interface Interface {
  onClose: () => void;
  value: string;
  id?: number;
  isEmail?: boolean;
}

const VerificationModal = ({ onClose, value, id, isEmail = false }: Interface) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const codeId = useAppSelector(selectCodeId);
  const profile = useAppSelector(selectProfile);
  const SECONDS = isEmail ? 59 : 35;

  const [code, setCode] = useState('');
  const [counter, setCounter] = useState(SECONDS);
  const [isCorrectCode, setIsCorrectCode] = useState<boolean | undefined>();

  useEffect(() => {
    const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleClose = () => {
    dispatch(setCodeId(''));
    onClose();
  };

  const handleCheckCode = async () => {
    const response = await dispatch(checkCodeAsync(value, code, codeId));
    setIsCorrectCode(response === 'isCorrect');
    if (response === 'isCorrect') {
      if (id) {
        if (isEmail) {
          await dispatch(
            updateUserEmailAsync({
              id,
              email: value,
              codeId,
              userUuid: profile?.userUuid ?? '',
            })
          );
        } else {
          await dispatch(
            updateUserPhoneAsync({
              id,
              phoneNumber: value,
              codeId,
              userUuid: profile?.userUuid ?? '',
            })
          );
        }
      } else {
        if (isEmail) {
          await dispatch(
            createUserEmailAsync({
              email: value,
              codeId,
              userUuid: profile?.userUuid ?? '',
            })
          );
        } else {
          await dispatch(
            createUserPhoneAsync({
              phoneNumber: value,
              codeId,
              userUuid: profile?.userUuid ?? '',
            })
          );
        }
      }
      handleClose();
    }
  };

  const handleChangeCode = (code: string) => {
    isCorrectCode === false && setIsCorrectCode(undefined);
    setCode(code);
  };

  const handleRefreshCode = async () => {
    if (counter === 0) {
      await dispatch(sendCodeAsync(value, isEmail ? 'email' : 'call'));
      isCorrectCode === false && setIsCorrectCode(undefined);
      setIsCorrectCode(undefined);
      setCode('');
      setCounter(SECONDS);
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth>
      <DialogTitle>{language['CONF_COD']}</DialogTitle>
      <DialogContent>
        <div className={`verification ${styles.verification}`}>
          <ReactInputVerificationCode
            autoFocus
            placeholder=""
            value={code}
            onChange={handleChangeCode}
          />
        </div>
        {isCorrectCode === false ? (
          <Alert variant="outlined" severity="warning">
            {language['INCORRECT_CODE']}
          </Alert>
        ) : null}
        <div
          className={`${styles.timer} ${counter === 0 ? styles.timer_active : ''}`}
          onClick={handleRefreshCode}>
          {language['REQ_NEW_CODE']} 00:{counter}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{language['CANCEL']}</Button>
        <Button onClick={handleCheckCode} disabled={!code.length}>
          {language['SAVE_BUTTON']}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerificationModal;
