import { createTheme, InputAdornment, TextField, ThemeProvider } from '@mui/material';
import React, { FC, ReactNode } from 'react';

interface BaseInputProps {
  placeholder?: string;
  autofocus?: boolean;
  startIcon?: ReactNode;
  onChange?: (value: string) => void;
}

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#F5F4F3',
          borderRadius: '12px',
          height: '44px',
          border: '0.5px solid rgba(0, 0, 0, 0.12)',
        },
        notchedOutline: {
          border: 'none',
        },
        input: {
          padding: '8px 16px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputAdornedStart: {
          paddingLeft: '0px !important',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: 'rgba(0,0,0,0.2)',
        },
      },
    },
  },
});

const BaseInput: FC<BaseInputProps> = ({ placeholder, autofocus, startIcon, onChange }) => {
  return (
    <ThemeProvider theme={theme}>
      <TextField
        autoFocus={autofocus}
        placeholder={placeholder}
        InputProps={{
          startAdornment: startIcon ? (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ) : undefined,
        }}
        onChange={(e) => onChange?.(e.target.value)}
      />
    </ThemeProvider>
  );
};

export default BaseInput;
