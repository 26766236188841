import React from 'react';
import { useAppSelector } from '../../../utils/hooks';
import { selectServices } from '../../../redux/loungeDataSlice';
import styles from './Service.module.css';
import icon1 from '../../../static/services/1.svg';
import icon2 from '../../../static/services/2.svg';
import icon3 from '../../../static/services/3.svg';
import icon4 from '../../../static/services/4.svg';
import icon5 from '../../../static/services/5.svg';
import icon6 from '../../../static/services/6.svg';
import icon7 from '../../../static/services/7.svg';
import icon8 from '../../../static/services/8.svg';
import icon9 from '../../../static/services/9.svg';
import icon10 from '../../../static/services/10.svg';
import icon11 from '../../../static/services/11.svg';
import icon12 from '../../../static/services/12.svg';
import icon13 from '../../../static/services/13.svg';
import icon14 from '../../../static/services/14.svg';
import icon15 from '../../../static/services/15.svg';
import icon16 from '../../../static/services/16.svg';
import icon17 from '../../../static/services/17.svg';
import icon18 from '../../../static/services/18.svg';
import icon19 from '../../../static/services/19.svg';
import icon20 from '../../../static/services/20.svg';
import icon21 from '../../../static/services/21.svg';
import icon22 from '../../../static/services/22.svg';
import icon23 from '../../../static/services/23.svg';
import icon24 from '../../../static/services/24.svg';
import icon25 from '../../../static/services/25.svg';
import icon26 from '../../../static/services/26.svg';
import icon27 from '../../../static/services/27.svg';
import icon28 from '../../../static/services/28.svg';
import icon29 from '../../../static/services/29.svg';
import icon30 from '../../../static/services/30.svg';
import icon31 from '../../../static/services/31.svg';
import icon32 from '../../../static/services/32.svg';

interface Interface {
  data: string;
}

const Service = ({ data }: Interface) => {
  const services = useAppSelector(selectServices);

  const getIcon = () => {
    switch (data) {
      case '1':
        return icon1;
      case '2':
        return icon2;
      case '3':
        return icon3;
      case '4':
        return icon4;
      case '5':
        return icon5;
      case '6':
        return icon6;
      case '7':
        return icon7;
      case '8':
        return icon8;
      case '9':
        return icon9;
      case '10':
        return icon10;
      case '11':
        return icon11;
      case '12':
        return icon12;
      case '13':
        return icon13;
      case '14':
        return icon14;
      case '15':
        return icon15;
      case '16':
        return icon16;
      case '17':
        return icon17;
      case '18':
        return icon18;
      case '19':
        return icon19;
      case '20':
        return icon20;
      case '21':
        return icon21;
      case '22':
        return icon22;
      case '23':
        return icon23;
      case '24':
        return icon24;
      case '25':
        return icon25;
      case '26':
        return icon26;
      case '27':
        return icon27;
      case '28':
        return icon28;
      case '29':
        return icon29;
      case '30':
        return icon30;
      case '31':
        return icon31;
      case '32':
        return icon32;
    }
  };

  return (
    <div className={styles.service_item}>
      <div>
        <img src={getIcon()} alt="" />
      </div>
      <div>{services[data]}</div>
    </div>
  );
};

export default Service;
