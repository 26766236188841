import { FC } from 'react';
import PhoneInput from 'react-phone-input-2';
import styles from './BasePhoneInput.module.css';

interface BasePhoneInputProps {
  value: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange: (value: string) => void;
}

const BasePhoneInput: FC<BasePhoneInputProps> = ({
  value,
  disabled,
  autoFocus = false,
  onChange,
}) => {
  return (
    <PhoneInput
      value={value}
      country={'ru'}
      onChange={onChange}
      isValid={(value, country: any) => value.length === country.format.match(/\./g).length}
      disabled={disabled}
      containerClass={styles.container}
      inputClass={styles.input}
      buttonClass={styles.dropdown}
      placeholder={'7 (987) 654 3210'}
      prefix={''}
      inputProps={{
        name: 'phone',
        required: true,
        autoFocus: autoFocus,
      }}
    />
  );
};

export default BasePhoneInput;
