import { Button, CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import { CSSProperties, FC, ReactNode } from 'react';

interface BaseButtonProps {
  children: ReactNode;
  width?: string;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  loading?: boolean;
  // По умолчанию - filled
  appearance?: 'filled' | 'tinted' | 'outlined';
  // По умолчанию: true
  fullWidth?: boolean;
  floating?: boolean;
  onClick?: () => void;
}

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          fontFamily: 'var(--font-gothic)',
          fontWeight: '700',
          fontSize: '16px',
          background: 'white',
          borderRadius: '12px',
          boxShadow: 'none',
          padding: '8px 0',
          textTransform: 'none',
        },
        containedPrimary: {
          fontFamily: 'var(--font-gothic)',
          fontWeight: '700',
          fontSize: '16px',
          textTransform: 'none',
          background: 'linear-gradient(302.01deg, #FAB215 -14.62%, #FAB215 113.75%)',
          borderRadius: '12px',
          boxShadow: 'none',
          padding: '8px 0',
        },
        textPrimary: {
          fontFamily: 'var(--font-gothic)',
          fontWeight: '700',
          fontSize: '16px',
          textTransform: 'none',
          background: '#F5F4F3',
          borderRadius: '12px',
          boxShadow: 'none',
          padding: '10px 0',
          color: '#FAB215',
        },
      },
    },
  },
});

const BaseButton: FC<BaseButtonProps> = ({
  children,
  fullWidth = true,
  appearance = 'filled',
  disabled,
  loading,
  floating,
  onClick,
}) => {
  const floatingStyle: CSSProperties = { position: 'absolute', bottom: 16, left: 16, right: 16 };
  const getVariant = () => {
    switch (appearance) {
      case 'filled':
        return 'contained';
      case 'outlined':
        return 'outlined';
      default:
        return 'text';
    }
  };

  return (
    <div style={floating ? floatingStyle : undefined}>
      <ThemeProvider theme={theme}>
        <Button
          sx={{
            opacity: disabled ? 0.5 : 1,
            color: disabled ? 'rgba(255,255,255, 0.8) !important' : undefined,
          }}
          variant={getVariant()}
          fullWidth={fullWidth}
          disabled={disabled}
          disableElevation
          onClick={onClick}>
          {loading ? <CircularProgress size={24.5} color="secondary" /> : children}
        </Button>
      </ThemeProvider>
    </div>
  );
};

export default BaseButton;
