import React from 'react';
import { useAppSelector } from '../../utils/hooks';
import { selectProfile } from '../../redux/profileDataSlice';
import EditPhone from '../../components/Profile/EditPhone';

const ProfileEditPhoneContainer = () => {
  const profile = useAppSelector(selectProfile);

  return profile ? <EditPhone profile={profile} /> : null;
};

export default ProfileEditPhoneContainer;
