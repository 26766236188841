import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CardModel } from '../models';
import httpClient from '../api/http-client';
import { AppDispatch, RootState } from './store';
import { selectProfile } from './profileDataSlice';
import { Order } from './loungeOrderPageSlice';

interface InitialState {
  loading: boolean;
  data: CardModel[];
  error: string;
  orders: Order[];
}

export const cardDataSlice = createSlice({
  name: 'cardData',
  initialState: {
    loading: false,
    data: [],
    error: '',
    orders: [],
  } as InitialState,
  reducers: {
    setData: (state, action: PayloadAction<CardModel[]>) => {
      state.data = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setOrders: (state, action: PayloadAction<Order[]>) => {
      state.orders = action.payload;
    },
  },
});

export const { setData, setLoading, setError, setOrders } =
  cardDataSlice.actions;

export const loadCardsAsync =
  (lang = 'ru') =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const profile = selectProfile(getState());
    dispatch(setLoading(true));
    dispatch(setError(''));
    try {
      const response = await httpClient.get(`cards?lang=${profile?.language || lang}`);
      dispatch(setData(response.data));
    } catch (e: any) {
      console.log('loadCardsAsync', e.message);
      dispatch(setError('Возникла ошибка при получении списка продуктов'));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const loadOrdersAsync = (productId?: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await httpClient.get(`order`);
    dispatch(setOrders(response.data));
  } catch (e: any) {
    console.log('loadOrdersAsync', e.message);
  }
};

export const selectCards = (state: RootState) => state.cardData.data;
export const selectCardsLoading = (state: RootState) => state.cardData.loading;
export const selectCardsError = (state: RootState) => state.cardData.error;
export const selectOrders = (state: RootState) => state.cardData.orders;

export default cardDataSlice.reducer;
