import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UserEmailCreateModel,
  UserEmailDeleteModel,
  UserEmailModel,
  UserEmailUpdateModel,
} from '../models';
import httpClient from '../api/http-client';
import { AppDispatch, RootState } from './store';
import { loadUserProfileAsync } from './profileDataSlice';

interface InitialState {
  userEmails: UserEmailModel[];
}

export const userEmailDataSlice = createSlice({
  name: 'userEmailData',
  initialState: {
    userEmails: [],
  } as InitialState,
  reducers: {
    setUserEmails: (state, action: PayloadAction<UserEmailModel[]>) => {
      state.userEmails = action.payload;
    },
  },
});

export const { setUserEmails } = userEmailDataSlice.actions;

export const loadUserEmailAsync = () => async (dispatch: AppDispatch) => {
  try {
    const response = await httpClient.get(`user/email`);
    dispatch(setUserEmails(response.data));
  } catch (e: any) {
    console.log('loadUserEmailAsync', e.message);
  } finally {
  }
};

export const createUserEmailAsync =
  (data: UserEmailCreateModel) => async (dispatch: AppDispatch) => {
    try {
      const response = await httpClient.post(`user/email`, data);
      if (response.status === 200) {
        dispatch(loadUserProfileAsync());
      }
    } catch (e: any) {
      console.log('createUserEmailAsync', e.message);
    } finally {
    }
  };

export const updateUserEmailAsync =
  (data: UserEmailUpdateModel) => async (dispatch: AppDispatch) => {
    try {
      const response = await httpClient.put(`user/email`, data);
      if (response.status === 200) {
        dispatch(loadUserProfileAsync());
      }
    } catch (e: any) {
      console.log('updateUserEmailAsync', e.message);
    } finally {
    }
  };

export const deleteUserEmailAsync =
  (data: UserEmailDeleteModel) => async (dispatch: AppDispatch) => {
    try {
      const response = await httpClient.delete(`user/email?id=${data.id}`);
      if (response.status === 200) {
        dispatch(loadUserProfileAsync());
      }
    } catch (e: any) {
      console.log('deleteUserEmailAsync', e.message);
    } finally {
    }
  };

export const selectUserEmails = (state: RootState) => state.userEmailData.userEmails;

export default userEmailDataSlice.reducer;
