import React, { ChangeEvent, useState } from 'react';
import { Input } from '../../../index';
import VerificationModal from '../../VerificationModal';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { UserEmailModel } from '../../../../models';
import { checkUserPhoneAsync, selectLanguage } from '../../../../redux/profileDataSlice';
import { sendCodeAsync } from '../../../../redux/registrationDataSlice';
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks';

interface Interface {
  onClose: () => void;
  email?: UserEmailModel;
}

const EditModal = ({ onClose, email }: Interface) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);

  const [value, setValue] = useState(email ? email.email : '');
  const [error, setError] = useState('');
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(event.target.value);
    error && setError('');
  };

  const [viewVerification, setViewVerification] = useState(false);

  const handleSave = async () => {
    const userState = await dispatch(checkUserPhoneAsync(value));

    if (userState === 'REGISTERED') {
      setError('Email уже зарегистрирован');
    } else {
      dispatch(sendCodeAsync(value, 'email'));
      setViewVerification(true);
    }
  };

  const handleClose = () => onClose();

  return (
    <>
      <Dialog open={true} onClose={handleClose} fullWidth>
        <DialogTitle>{email ? language['EDIT_EMAIL'] : language['ADD_EMAIL']}</DialogTitle>
        <DialogContent>
          <Input
            autoFocus
            error={!value}
            onChange={handleChange}
            placeholder={'Email'}
            value={value}
          />
          {error ? (
            <Alert variant="outlined" severity="warning">
              {error}
            </Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{language['CANCEL']}</Button>
          <Button onClick={handleSave} disabled={!value.length}>
            {language['SAVE_BUTTON']}
          </Button>
        </DialogActions>
      </Dialog>
      {viewVerification ? (
        <VerificationModal
          onClose={() => setViewVerification(false)}
          value={value}
          isEmail={true}
          id={email?.id}
        />
      ) : null}
    </>
  );
};

export default EditModal;
