import React, { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Input, InputTitle } from '../../../components';
import { selectLanguage, updateUserProfileAsync } from '../../../redux/profileDataSlice';
import { UserProfileModel } from '../../../models';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { transliteration } from '../../../utils/TextUtils';
import { PROFILE_EDIT_EMAIL, PROFILE_EDIT_PHONE } from '../../../utils/config';
import styles from './EditProfile.module.css';
import { BaseHeader } from '../../../ui';

interface Interface {
  profile: UserProfileModel;
}

const EditProfile = ({ profile }: Interface) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);

  const [firstname, setFirstname] = useState(profile.firstname);
  const [surname, setSurname] = useState(profile.surname);
  const [patronymic, setPatronymic] = useState(profile.patronymic);
  const [latinFirstname, setLatinFirstname] = useState(profile.latinFirstname || '');
  const [latinSurname, setLatinSurname] = useState(profile.latinSurname || '');
  const [latinPatronymic, setLatinPatronymic] = useState(profile.latinPatronymic || '');

  const handleChangeFirstname = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value.trim();
    setFirstname(value);
    setLatinFirstname(transliteration(value));
  };
  const handleChangeSurname = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value.trim();
    setSurname(value);
    setLatinSurname(transliteration(value));
  };
  const handleChangePatronymic = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value.trim();
    setPatronymic(value);
    setLatinPatronymic(transliteration(value));
  };
  const handleChangeLatinFirstname = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setLatinFirstname(event.target.value);
  const handleChangeLatinSurname = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setLatinSurname(event.target.value);
  const handleChangeLatinPatronymic = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setLatinPatronymic(event.target.value);

  const handleUpdate = () => {
    dispatch(
      updateUserProfileAsync({
        userGuid: profile.userUuid,
        firstname: firstname || null,
        surname: surname || null,
        patronymic: patronymic || null,
        latinFullname: `${latinSurname} ${latinFirstname} ${latinPatronymic}` || null,
        latinFirstname: latinFirstname || null,
        latinSurname: latinSurname || null,
        latinPatronymic: latinPatronymic || null,
        language: profile.language,
      })
    );
  };

  const handleToggleViewEmailModal = () => history.push(PROFILE_EDIT_EMAIL);

  const handleToggleViewPhoneModal = () => history.push(PROFILE_EDIT_PHONE);

  return (
    <>
      <BaseHeader title={language['PROFILE_EDIT']} showBack solidBackground />
      <div className={styles.root}>
        <div>
          <>
            <div className={styles.two_column}>
              <InputTitle style={{ flex: 1 }} title={language['NAME']} />
              <InputTitle style={{ flex: 1 }} title={'First Name'} />
            </div>
            <div className={styles.two_column}>
              <Input
                error={!firstname}
                onChange={handleChangeFirstname}
                placeholder={language['NAME']}
                value={firstname}
              />
              <Input
                error={!latinFirstname}
                onChange={handleChangeLatinFirstname}
                placeholder={'First Name'}
                value={latinFirstname}
              />
            </div>
            <div className={styles.two_column}>
              <InputTitle style={{ flex: 1 }} title={language['SURNAME']} />
              <InputTitle style={{ flex: 1 }} title={'Last Name'} />
            </div>
            <div className={styles.two_column}>
              <Input
                error={!surname}
                onChange={handleChangeSurname}
                placeholder={language['SURNAME']}
                value={surname}
              />
              <Input
                error={!latinSurname}
                onChange={handleChangeLatinSurname}
                placeholder={'Last Name'}
                value={latinSurname}
              />
            </div>
            <div className={styles.two_column}>
              <InputTitle style={{ flex: 1 }} title={language['PATRONYMIC']} />
              <InputTitle style={{ flex: 1 }} title={'Middle Name'} />
            </div>
            <div className={styles.two_column}>
              <Input
                error={!patronymic}
                onChange={handleChangePatronymic}
                placeholder={language['PATRONYMIC']}
                value={patronymic}
              />
              <Input
                error={!latinPatronymic}
                onChange={handleChangeLatinPatronymic}
                placeholder={'Middle Name'}
                value={latinPatronymic}
              />
            </div>
            <InputTitle title={'E-mail'} />
            <div className={styles.group} onClick={handleToggleViewEmailModal}>
              <div className={styles.group_text}>
                {profile.emails.map((_) => (
                  <div key={_.id}>{_.email}</div>
                ))}
              </div>
              <div>
                <ArrowForwardIosIcon className={styles.group_arrow} />
              </div>
            </div>
            <InputTitle title={language['PH_NUM']} />
            <div className={styles.group} onClick={handleToggleViewPhoneModal}>
              <div className={styles.group_text}>
                {profile.phoneList.map((_) => (
                  <div key={_.id}>{`+${_.phoneNumber}`}</div>
                ))}
              </div>
              <div>
                <ArrowForwardIosIcon className={styles.group_arrow} />
              </div>
            </div>
          </>
        </div>
        <div>
          <Button
            variant="contained"
            classes={{ root: styles.button }}
            fullWidth
            onClick={handleUpdate}
            disabled={
              !firstname ||
              !surname ||
              !patronymic ||
              !latinFirstname ||
              !latinSurname ||
              !latinPatronymic ||
              (firstname === profile.firstname &&
                surname === profile.surname &&
                patronymic === profile.patronymic &&
                latinFirstname === profile.latinFirstname &&
                latinSurname === profile.latinSurname &&
                latinPatronymic === profile.latinPatronymic)
            }>
            {language['BTN_APPLY']}
          </Button>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
