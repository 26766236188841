import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Skeleton } from '@mui/material';
import { LoungeRow, SearchBar } from '../../components';
import { selectLanguage, selectProfile } from '../../redux/profileDataSlice';
import { selectLoungeFilter } from '../../redux/loungePageSlice';
import {
  loadCitiesAsync,
  loadLoungesAsync,
  loadServicesAsync,
  selectCities,
  selectLounges,
  selectLoungesLoading,
} from '../../redux/loungeDataSlice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { LOUNGES_FILTER } from '../../utils/config';
import styles from './LoungesContainer.module.css';
import { BaseHeader } from '../../ui';
import BaseHeaderButton from '../../ui/BaseHeaderButton';
import { FilterAltOutlined, SearchRounded } from '@mui/icons-material';

const LoungesContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const language = useAppSelector(selectLanguage);
  const loading = useAppSelector(selectLoungesLoading);
  const lounges = useAppSelector(selectLounges);
  const cities = useAppSelector(selectCities);
  const profile = useAppSelector(selectProfile);
  const filters = useAppSelector(selectLoungeFilter);

  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const hasActiveFilters = useAppSelector((state) => {
    const f = selectLoungeFilter(state);
    return !!f.city || !!f.airports.length || !!f.services.length || !!searchQuery;
  });

  useEffect(() => {
    if (!isSearchVisible) {
      setSearchQuery('');
    }
  }, [isSearchVisible]);

  useEffect(() => {
    if (ref && ref.current) {
      const computedStyle = getComputedStyle(ref.current);
      let elementWidth = ref.current.clientWidth;
      elementWidth -=
        parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
      setWidth(elementWidth);
    }
  }, []);

  useEffect(() => {
    dispatch(loadLoungesAsync(profile?.language));
    dispatch(loadCitiesAsync(profile?.language));
    dispatch(loadServicesAsync(profile?.language));
  }, [profile]);

  const handleViewFilter = () => history.push(LOUNGES_FILTER);

  const filteredData = lounges.filter((_) => {
    const id = `${_.iataCode}_${_.airportName}_${_.terminalName}_${_.cityCode}`;

    const matchSearch =
      !searchQuery ||
      cities[_.cityCode]?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      _.airportName?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      _.terminalName?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      _.iataCode?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      _.loungeName?.toLowerCase()?.includes(searchQuery.toLowerCase());

    return (
      (!filters.city || _.cityCode === filters.city) &&
      (!filters.airports.length || filters.airports.includes(id)) &&
      (!filters.services.length ||
        filters.services.filter((service) => _.loungeServices?.includes(service)).length) &&
      matchSearch
    );
  });

  return (
    <>
      <BaseHeader
        title={language['LOUNGE']}
        withLogo
        solidBackground
        titleSmall
        actions={
          <>
            <BaseHeaderButton type="circle" onClick={() => setIsSearchVisible(true)}>
              <SearchRounded />
            </BaseHeaderButton>
            <BaseHeaderButton
              type="circle"
              floating={hasActiveFilters}
              showDot={hasActiveFilters}
              onClick={handleViewFilter}>
              <FilterAltOutlined />
            </BaseHeaderButton>
          </>
        }
      />
      <SearchBar
        visible={isSearchVisible}
        onChange={setSearchQuery}
        onCancel={() => setIsSearchVisible(false)}
      />
      <div className={styles.root} ref={ref}>
        {loading ? (
          Array.from(Array(3).keys()).map((_) => (
            <div key={_} style={{ marginBottom: 32 }}>
              <Skeleton variant="rectangular" height={250} />
              <Skeleton
                variant="rectangular"
                height={20}
                style={{ margin: '8px 0 4px', width: '60%' }}
              />
              <Skeleton variant="rectangular" height={14} style={{ width: '40%' }} />
            </div>
          ))
        ) : hasActiveFilters && !filteredData.length ? (
          <Alert variant="outlined" severity="warning">{language['NO_LOUNGES_WAS_FOUND']}</Alert>
        ) : (
          filteredData.map((_) => <LoungeRow key={_.id} data={_} cities={cities} width={width} />)
        )}
      </div>
    </>
  );
};

export default LoungesContainer;
