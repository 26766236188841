import React, { CSSProperties } from 'react';
import styles from './InputTitle.module.css';

interface Interface {
  title: string;
  style?: CSSProperties;
  required?: boolean;
}

const InputTitle = ({ title, style, required }: Interface) => {
  return (
    <div className={styles.root} style={style}>
      {title}
      {required && <span className={styles.inputRequired}>*</span>}
    </div>
  );
};

export default InputTitle;
