import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Chip, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { Cancel, Clear } from '@mui/icons-material';
import { LoungeFilterCitySearch, LoungeFilterServices } from '../../components';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { LOUNGES } from '../../utils/config';
import {
  loadCitiesAsync,
  loadLoungesAsync,
  loadServicesAsync,
  selectCities,
  selectLounges,
} from '../../redux/loungeDataSlice';
import {
  resetLoungeFilterSortOrder,
  selectLoungeFilter,
  setFilters,
} from '../../redux/loungePageSlice';
import { selectLanguage, selectProfile } from '../../redux/profileDataSlice';
import styles from './LoungesFilterContainer.module.css';
import { BaseButton, BaseHeader } from '../../ui';

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 6;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LoungesFilterContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const lounges = useAppSelector(selectLounges);
  const cities = useAppSelector(selectCities);
  const filters = useAppSelector(selectLoungeFilter);
  const language = useAppSelector(selectLanguage);
  const profile = useAppSelector(selectProfile);

  useEffect(() => {
    profile && dispatch(loadLoungesAsync(profile?.language));
    profile && dispatch(loadCitiesAsync(profile?.language));
    profile && dispatch(loadServicesAsync(profile?.language));
  }, [profile]);

  const [viewCitySelect, setViewCitySelect] = useState(false);
  const handleViewCitySelect = () => setViewCitySelect(!viewCitySelect);

  const handleClear = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setFilterCity('');
    setFilterAirports([]);
  };

  const [filterCity, setFilterCity] = useState(filters.city);

  const handleChangeCity = (id: string) => {
    setFilterCity(id);
    setFilterAirports([]);
  };

  const [filterAirports, setFilterAirports] = useState<string[]>(filters.airports);

  const handleChangeAirports = (event: SelectChangeEvent<typeof filterAirports>) => {
    const {
      target: { value },
    } = event;
    setFilterAirports(typeof value === 'string' ? value.split(',') : value);
  };
  const handleDeleteAirport = (value: string) =>
    setFilterAirports((prevState) =>
      prevState.some((_) => _ === value)
        ? prevState.filter((_) => _ !== value)
        : [...prevState, value]
    );

  const getAirports = (): {
    id: string;
    name: string;
    cityCode: string;
    terminalName: string;
  }[] => {
    const airports: { id: string; name: string; cityCode: string; terminalName: string }[] = [];
    lounges
      .filter((_) => !filterCity || _.cityCode === filterCity)
      .forEach((lounge) => {
        const id = `${lounge.iataCode}_${lounge.airportName}_${lounge.terminalName}_${lounge.cityCode}`;
        const exist = airports.find((_) => _.id === id);

        if (exist) {
          return;
        }

        const iataCode = lounge.iataCode ? `${lounge.iataCode} — ` : ``;
        const cityNameForTrainStation =
          lounge.iataCode === 'RZD' ? `${cities[lounge.cityCode]}, ` : '';
        const terminalName = lounge.terminalName ? `, ${lounge.terminalName}` : '';
        const name = `${iataCode}${cityNameForTrainStation}${
          lounge.airportName ? lounge.airportName : cities[lounge.cityCode]
        }${terminalName}`;

        airports.push({
          id,
          name,
          terminalName: lounge.terminalName,
          cityCode: lounge.cityCode,
        });
      });

    return airports.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  const airports = getAirports();

  const [filterServices, setFilterServices] = useState<string[]>(filters.services);

  const handleChangeServices = (id: string) => {
    setFilterServices((prevState) =>
      prevState.some((_) => _ === id) ? prevState.filter((_) => _ !== id) : [...prevState, id]
    );
  };

  const handleSetFilters = () => {
    dispatch(
      setFilters({
        city: filterCity,
        airports: filterAirports,
        services: filterServices,
      })
    );
    history.push(LOUNGES);
  };

  const handleReset = () => {
    dispatch(resetLoungeFilterSortOrder());
    setFilterCity('');
    setFilterAirports([]);
    setFilterServices([]);
  };

  return (
    <>
      <BaseHeader title={language['FILTER']} showBack onBack={() => history.push(LOUNGES)}>
        <div className={styles.filter}>
          <div className={styles.filter_title}>{language['CITY']}</div>
          <div className={styles.city_btn} onClick={handleViewCitySelect}>
            {filterCity ? (
              cities[filterCity]
            ) : (
              <span className={styles.empty_city}>{language['ENTER_CITY']}</span>
            )}
            {filterCity ? (
              <div onClick={handleClear} className={styles.clear}>
                <Clear />
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.filter}>
          <div className={styles.filter_title}>{language['AIRPORT_TRST']}</div>
          <Select
            classes={{ select: styles.airports }}
            fullWidth
            displayEmpty
            multiple
            value={filterAirports}
            onChange={handleChangeAirports}
            input={<OutlinedInput className={styles.test} />}
            MenuProps={MenuProps}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return language['SELECT_AIRPORT'];
              }
              return selected.map((value: any) => {
                const name = (airports.find((_) => _.id === value) || { name: '---' }).name;
                return (
                  <Chip
                    key={value}
                    label={name}
                    clickable
                    deleteIcon={<Cancel onMouseDown={(event) => event.stopPropagation()} />}
                    className={styles.selected}
                    onDelete={() => handleDeleteAirport(value)}
                  />
                );
              });
            }}>
            {airports.map((_) => (
              <MenuItem key={_.id} value={_.id}>
                {_.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </BaseHeader>
      <div className={styles.root}>
        <LoungeFilterServices filterServices={filterServices} onChange={handleChangeServices} />
        {viewCitySelect ? (
          <LoungeFilterCitySearch onClose={handleViewCitySelect} onSelect={handleChangeCity} />
        ) : null}
      </div>
      <div className={styles.apply}>
        {filters.city || filters.airports.length || filters.services.length ? (
          <BaseButton appearance="tinted" onClick={handleReset}>
            {language['RESET_BUTTON']}
          </BaseButton>
        ) : null}
        <BaseButton onClick={handleSetFilters}>{language['BTN_APPLY']}</BaseButton>
      </div>
    </>
  );
};

export default LoungesFilterContainer;
