import { FC, ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';
import styles from './BaseBottomSheet.module.css';

interface BaseBottomSheetProps {
  title?: string;
  children?: ReactNode;
  actionButtons?: ReactNode;
  visible?: boolean;
  onClose?: () => void;
}

const BaseBottomSheet: FC<BaseBottomSheetProps> = ({
  title,
  children,
  actionButtons,
  visible,
  onClose,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const opacity = visible ? 1 : 0;

  return createPortal(
    <>
      <div
        className={styles.backdrop}
        style={{ opacity, zIndex: visible ? 100 : -1 }}
        onClick={onClose}
      />
      <div
        ref={modalRef}
        className={styles.modal}
        style={{
          opacity,
          transform: `translateY(${visible ? 0 : modalRef.current?.offsetHeight || 10}px)`,
          zIndex: visible ? 101 : -1,
        }}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{children}</div>
        {actionButtons && <div className={styles.buttons}>{actionButtons}</div>}
      </div>
    </>,
    document.getElementsByTagName('body')![0]
  );
};

export default BaseBottomSheet;
