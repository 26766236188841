import React, { useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Box, Paper } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { selectLanguage } from '../../redux/profileDataSlice';
import { useAppSelector } from '../../utils/hooks';
import { ABOUT, LOUNGES, MAIN, PROFILE, SUPPORT } from '../../utils/config';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import styles from './NavigationBottom.module.css';

export const NavigationBottom = () => {
  const location = useLocation();
  const language = useAppSelector(selectLanguage);

  const links = [
    {
      link: MAIN,
      label: language['MAIN'],
      icon: <HomeOutlinedIcon />,
    },
    {
      link: LOUNGES,
      label: language['LOUNGE'],
      icon: <GridViewIcon />,
    },
    {
      link: PROFILE,
      label: language['PROFILE'],
      icon: <PersonOutlineOutlinedIcon />,
    },
  ];

  const getLinkIndex = () => {
    if (location.pathname === MAIN) {
      return 0;
    }
    if (location.pathname.includes(LOUNGES)) {
      return 1;
    }
    if (
      location.pathname.includes(PROFILE) ||
      location.pathname === SUPPORT ||
      location.pathname === ABOUT
    ) {
      return 2;
    }

    return 0;
  };

  const [value, setValue] = useState(getLinkIndex());

  useEffect(() => {
    setValue(getLinkIndex());
  }, [location]);

  return (
    <Box>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2 }} elevation={4}>
        <BottomNavigation
          showLabels
          value={value}
          classes={{
            root: styles.main_root,
          }}
          onChange={(event, newValue) => setValue(newValue)}>
          {links.map((link, idx) => (
            <BottomNavigationAction
              key={idx}
              component={Link}
              to={link.link}
              label={link.label}
              icon={link.icon}
              classes={{
                root: styles.root,
                selected: styles.selected,
              }}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </Box>
  );
};
