import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { selectLanguage, setData, setUserUuid } from '../../../redux/profileDataSlice';
import { useAppDispatch, useAppSelector, useAuth } from '../../../utils/hooks';
import styles from './Logout.module.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Logout = () => {
  const dispatch = useAppDispatch();
  const { logout } = useAuth();
  const language = useAppSelector(selectLanguage);

  const [viewDialog, setViewDialog] = useState(false);
  const handleToggleViewDialog = () => setViewDialog(!viewDialog);

  const handleLogout = () => {
    dispatch(setData(null));
    dispatch(setUserUuid(''));
    logout();
    setViewDialog(!viewDialog);
  };

  return (
    <>
      <Button
        variant="text"
        fullWidth
        color="inherit"
        onClick={handleToggleViewDialog}
        style={{ height: 48 }}>
        <div className={styles.button_inner}>
          <div className={styles.button_content} style={{ marginLeft: 4 }}>
            <div className={styles.button_text}>{language['BTN_LOG_OUT']}</div>
            <ArrowForwardIosIcon className={styles.button_arrow} />
          </div>
        </div>
      </Button>
      {viewDialog ? (
        <Dialog open={true} onClose={handleToggleViewDialog}>
          <DialogTitle>{language['YOU_WANT_LOGOUT']}</DialogTitle>
          <DialogActions>
            <Button onClick={handleToggleViewDialog}>{language['CANCEL']}</Button>
            <Button onClick={handleLogout} color="warning">
              {language['BTN_LOG_OUT']}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default Logout;
