import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import { InputTitle } from '../../../components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { selectLanguage } from '../../../redux/profileDataSlice';
import { deleteUserPhoneAsync } from '../../../redux/userPhoneDataSlice';
import { UserPhoneModel, UserProfileModel } from '../../../models';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { PROFILE_EDIT } from '../../../utils/config';
import styles from './EditPhone.module.css';
import EditModal from './EditModal';
import { BaseButton, BaseHeader } from '../../../ui';

interface Interface {
  profile: UserProfileModel;
}

const EditPhone = ({ profile }: Interface) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);

  const handleBack = () => history.push(PROFILE_EDIT);

  const [viewAddModal, setViewAddModal] = useState(false);
  const handleToggleViewAddModal = () => setViewAddModal(!viewAddModal);

  const [deleteId, setDeleteId] = useState<number | undefined>();
  const handleViewDeleteModal = (id: number | undefined) => setDeleteId(id);
  const [editPhone, setEditPhone] = useState<UserPhoneModel | undefined>();
  const handleViewEditModal = (id: number | undefined) =>
    setEditPhone(profile.phoneList.find((_) => _.id === id));

  const handleDelete = () => {
    deleteId &&
      dispatch(
        deleteUserPhoneAsync({
          id: deleteId,
          userUuid: profile.userUuid,
        })
      );
    setDeleteId(undefined);
  };

  const handleCloseModal = () => {
    viewAddModal && setViewAddModal(false);
    editPhone && setEditPhone(undefined);
  };

  return (
    <>
      <BaseHeader title={language['EDIT_PHONENUMBER']} showBack solidBackground />
      <div className={styles.root}>
        <div>
          {profile.phoneList.map((_, index) => (
            <React.Fragment key={_.id}>
              <InputTitle title={`${language['PH_NUM']} ${index + 1}`} />
              <div className={styles.row}>
                <div
                  className={`${styles.input} ${_.primary ? styles.input_disabled : ''}`}
                  onClick={() => handleViewEditModal(_.primary ? undefined : _.id)}>
                  {_.phoneNumber}
                </div>
                <IconButton
                  color="warning"
                  disabled={_.primary}
                  onClick={() => handleViewDeleteModal(_.id)}>
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            </React.Fragment>
          ))}
          <Button
            className={styles.button_transparent}
            variant="text"
            fullWidth
            onClick={handleToggleViewAddModal}>
            <div className={styles.button_inner}>
              <div className={styles.button_content}>
                <div className={styles.button_text}>{language['ADD_PHONENUMBER']}</div>
                <ArrowForwardIosIcon className={styles.button_arrow} />
              </div>
            </div>
          </Button>
        </div>
        <BaseButton floating onClick={handleBack}>
          {language['BTN_APPLY']}
        </BaseButton>
      </div>
      {deleteId ? (
        <Dialog open={true} onClose={() => handleViewDeleteModal(undefined)}>
          <DialogTitle>{language['WANT_DELETE_PHONENUMBER']}</DialogTitle>
          <DialogActions>
            <Button onClick={() => handleViewDeleteModal(undefined)}>{language['CANCEL']}</Button>
            <Button onClick={handleDelete} color="warning">
              {language['DELETE_STR']}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {viewAddModal || editPhone ? (
        <EditModal onClose={handleCloseModal} phone={editPhone} />
      ) : null}
    </>
  );
};

export default EditPhone;
