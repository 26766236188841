import React from 'react';
import { useHistory } from 'react-router-dom';
import { EditProfile } from '../../components';
import { selectProfile } from '../../redux/profileDataSlice';
import { useAppSelector } from '../../utils/hooks';
import { PROFILE } from '../../utils/config';

const ProfileEditContainer = () => {
  let history = useHistory();
  const profile = useAppSelector(selectProfile);

  if (!profile) {
    history.push(PROFILE);
    return null;
  }

  return <EditProfile profile={profile} />;
};

export default ProfileEditContainer;
