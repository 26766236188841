const parseCurrencyWithPennies = (value: number) => {
  if (value === 0) return Number(`0.00`);
  const pennies = value.toString().substring(2);
  const rubles = value.toString().slice(0, -2);
  return Number(`${rubles}.${pennies}`);
};

const formatCurrency = (value: number, { parsePennies = true, displayPennies = false } = {}) =>
  Intl.NumberFormat('Ru-ru', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: displayPennies ? 2 : 0,
  }).format(parsePennies ? parseCurrencyWithPennies(value) : value);

export { formatCurrency };
