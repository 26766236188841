import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortOrder } from '../utils/Constants';
import { RootState } from './store';

interface Interface {
  filter: string[];
  sortOrder: number;
}

export const visitPageSlice = createSlice({
  name: 'visitPage',
  initialState: {
    filter: [],
    sortOrder: SortOrder.desc,
  } as Interface,
  reducers: {
    setVisitFilter: (state, action: PayloadAction<string>) => {
      const value = action.payload;

      const exist = state.filter.some((_) => _ === value);

      if (exist) {
        state.filter = state.filter.filter((_) => _ !== value);
      } else {
        state.filter = [...state.filter, value];
      }
    },
    resetVisitFilterSortOrder: (state) => {
      state.filter = [];
      state.sortOrder = SortOrder.desc;
    },
    setSortOrder: (state, action: PayloadAction<number>) => {
      state.sortOrder = action.payload;
    },
  },
});

export const { setVisitFilter, setSortOrder, resetVisitFilterSortOrder } = visitPageSlice.actions;

export const selectVisitFilters = (state: RootState) => state.visitPage.filter;
export const selectVisitSortOrder = (state: RootState) => state.visitPage.sortOrder;

export default visitPageSlice.reducer;
