import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { ArrowForwardIos, ChatBubbleOutline } from '@mui/icons-material';
import { Header, LanguageSelector, Logout } from '../../index';
import SupportModal from '../SupportModal';
import { selectLanguage } from '../../../redux/profileDataSlice';
import { useAppSelector } from '../../../utils/hooks';
import { ABOUT, PROFILE_EDIT, SUPPORT } from '../../../utils/config';
import { UserProfileModel } from '../../../models';
import styles from './ViewProfile.module.css';

interface Interface {
  profile: UserProfileModel | null;
}

const ViewProfile = ({ profile }: Interface) => {
  const history = useHistory();
  const language = useAppSelector(selectLanguage);

  const [viewSupport, setViewSupport] = useState(false);
  const handleToggleViewSupportModal = () => setViewSupport(!viewSupport);

  const handleOpenAboutPage = () => history.push(ABOUT);

  return (
    <div className={styles.inner}>
      <div className={styles.main}>
        <Header
          actions={
            <NavLink to={PROFILE_EDIT} className={styles.edit_link}>
              {language['EDIT_PROF']}
            </NavLink>
          }
        />
        <div className={styles.title}>{language['PROFILE']}</div>
        <div className={styles.name}>
          {profile?.firstname} {profile?.surname}
        </div>
        <div className={styles.phones}>
          <div className={styles.phones_title}>{language['PH_NUM']}</div>
          {profile?.phoneList.map((_) => (
            <div key={_.id} className={styles.phones_value}>
              {`+${_.phoneNumber}`}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          variant="text"
          fullWidth
          color="inherit"
          style={{ height: 48 }}
          onClick={handleToggleViewSupportModal}>
          <div className={styles.button_inner}>
            <ChatBubbleOutline className={styles.button_icon} />
            <div className={styles.button_content}>
              <div className={styles.button_text}>{language['SUPPORT']}</div>
              <ArrowForwardIos className={styles.button_arrow} />
            </div>
          </div>
        </Button>
        {profile ? <LanguageSelector profile={profile} /> : null}
        <Button
          onClick={handleOpenAboutPage}
          variant="text"
          fullWidth
          color="inherit"
          style={{ height: 48 }}>
          <div className={styles.button_inner}>
            <div className={styles.button_content} style={{ marginLeft: 4 }}>
              <div className={styles.button_text}>{language['ABOUT_US']}</div>
              <ArrowForwardIos className={styles.button_arrow} />
            </div>
          </div>
        </Button>
        <Button
          download="Пользовательское_соглашение.pdf"
          href={`/files/eula_${profile?.language}.pdf`}
          variant="text"
          fullWidth
          color="inherit"
          style={{ height: 48 }}>
          <div className={styles.button_inner}>
            <div className={styles.button_content} style={{ marginLeft: 4 }}>
              <div className={styles.button_text}>{language['USE_TERM']}</div>
              <ArrowForwardIos className={styles.button_arrow} />
            </div>
          </div>
        </Button>
        <Logout />
      </div>
      {viewSupport ? <SupportModal onClose={handleToggleViewSupportModal} /> : null}
    </div>
  );
};

export default ViewProfile;
