import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { differenceInHours } from 'date-fns';
import BaseHeader from '../../ui/BaseHeader';
import PaymentCard from '../../components/Order/PaymentCard';
import BaseBottomSheet from '../../ui/BaseBottomSheet';
import BaseButton from '../../ui/BaseButton';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import {
  CostResponse,
  createOrder,
  createOrderPayment,
  loadOrderCosts,
  Order,
  OrderPayload,
  OrderState,
  OrderType,
  PaymentPayload,
  Platform,
  selectLoadingCreateOrder,
  selectLoadingLoungeOrderCosts,
  selectLoungeOrder,
  selectLoungeOrderAdults,
  selectLoungeOrderCosts,
  selectLoungeOrderPayment,
  selectLoungeOrderUsers,
  setOrder,
} from '../../redux/loungeOrderPageSlice';
import { loadLoungesAsync, selectLounges } from '../../redux/loungeDataSlice';
import { selectLanguage } from '../../redux/profileDataSlice';
import { formatCurrency } from '../../utils/currency';
import { LOUNGE_ORDER, ORDER } from '../../utils/config';
import { CardModel } from '../../models';
import styles from './PaymentMethod.module.css';
import { loadOrdersAsync } from '../../redux/cardDataSlice';

const PaymentMethod = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { id: loungeId } = useParams<{ id: string }>();

  const language = useAppSelector(selectLanguage);
  const productCosts = useAppSelector(selectLoungeOrderCosts);
  const loadingProductCosts = useAppSelector(selectLoadingLoungeOrderCosts);
  const users = useAppSelector(selectLoungeOrderUsers);
  const adults = useAppSelector(selectLoungeOrderAdults);
  const order = useAppSelector(selectLoungeOrder);
  const loadingCreateOrder = useAppSelector(selectLoadingCreateOrder);
  const payment = useAppSelector(selectLoungeOrderPayment);
  const lounges = useAppSelector(selectLounges);
  const lounge = useAppSelector((state) => selectLounges(state).find((_) => _.id === +loungeId));

  const [selectedCost, setSelectedCost] = useState<CostResponse | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<CardModel | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const sortedProducts = useMemo(
    () =>
      [...productCosts].sort(
        (a, b) =>
          a.costs[0].paymentType?.localeCompare(b.costs[0].paymentType) ||
          b.costs[0].amount - a.costs[0].amount
      ),
    [productCosts]
  );

  const fetchOrderCosts = async () => {
    !lounges.length && (await dispatch(loadLoungesAsync()));

    const loungeUuid = lounges.find((_) => _.id === +loungeId)?.loungeUuid;
    loungeUuid && (await dispatch(loadOrderCosts(loungeUuid)));
  };

  useEffect(() => {
    if (!users.length) {
      history.push(LOUNGE_ORDER.replace(':id', loungeId));
    }
    fetchOrderCosts();
  }, [loungeId, lounges]);

  const handlePaymentSelect = (product: CardModel, cost: CostResponse) => {
    setSelectedProduct(product);
    setSelectedCost({ ...cost, amount: !product.prepaid ? 0 : cost.amount });
    setIsModalVisible(true);
  };

  const handleConfirmOrder = async () => {
    if (!lounge || !selectedProduct) {
      return;
    }

    if (order) {
      await createPayment(order);
      return;
    }

    const idempotencyKey = uuidv4();
    const firstUser = users[0];
    const orderPayload: OrderPayload = {
      orderType: lounge.loungeOwner === 'GWP' ? OrderType.GreyWallPass : OrderType.DreamFolks,
      idempotencyKey: idempotencyKey,
      productUuid: selectedProduct.productId,
      loungeUuid: lounge.loungeUuid,
      firstname: firstUser.firstname,
      surname: firstUser.surname,
      phone: firstUser.phoneNumber,
      email: firstUser.email,
      adults: adults,
      children: 0,
    };
    const newOrder = await dispatch(createOrder(orderPayload));
    await createPayment(newOrder);
  };

  const createPayment = async (orderForPayment: Order | undefined) => {
    if (!selectedCost || !orderForPayment || !selectedCost.paymentType) {
      return;
    }
    const hoursPassed = differenceInHours(new Date(), new Date(orderForPayment.createdAt));
    const idempotencyKey = hoursPassed > 23 ? uuidv4() : orderForPayment.idempotencyKey;

    const paymentPayload: PaymentPayload = {
      idempotencyKey,
      orderId: orderForPayment.orderId,
      paymentType: selectedCost.paymentType,
      platform: Platform.Mobile,
      callbackUri: process.env.REACT_APP_PAYMENT_CALLBACK_URI || '',
    };
    await dispatch(createOrderPayment(paymentPayload));
  };

  useEffect(() => {
    if (order?.orderState === OrderState.NoPaymentRequired) {
      dispatch(loadOrdersAsync());
      alert('Заказ успешно создан');
      history.push(ORDER.replace(':orderId', String(order.orderId)));
      dispatch(setOrder(null));
    }
  }, [order]);

  useEffect(() => {
    if (payment) {
      window.location.href = payment.url;
    }
  }, [payment]);

  return (
    <div>
      <BaseHeader
        title={language['PAY_METHOD']}
        subtitle={language['CHOOSE_BANK']}
        showBack
        onBack={history.goBack}
      />
      <div className={styles.container}>
        <div className={styles.title}>{language['BANK_PRODUCT']}</div>
        {loadingProductCosts
          ? Array.from(Array(3).keys()).map((_) => (
              <Skeleton key={_} variant="rectangular" style={{ height: 101 }} />
            ))
          : sortedProducts.map((item, idx) =>
              item.costs.map((cost, index) => (
                <PaymentCard
                  key={`${idx}_${index}`}
                  bankId={item.product.bankId}
                  type={item.product.prepaid ? cost.paymentType : undefined}
                  prepaid={item.product.prepaid}
                  description={item.product.productName}
                  amount={item.product.prepaid ? formatCurrency(cost.amount ?? 0) : `от 0₽`}
                  onClick={cost.amount !== null && cost.amount !== undefined
                    ? () => handlePaymentSelect(item.product, cost)
                    : undefined}
                />
              ))
            )}
      </div>
      <BaseBottomSheet
        visible={isModalVisible}
        title={language['CONF_ORDER']}
        actionButtons={[
          <BaseButton key={'amount'} loading={loadingCreateOrder} onClick={handleConfirmOrder}>
            {selectedCost?.amount
              ? `${language['ORDER_BUTTON']} ${formatCurrency(selectedCost.amount)}`
              : language['ORDER_BUTTON_1']}
          </BaseButton>,
          <BaseButton key={'cancel'} appearance="tinted" onClick={() => setIsModalVisible(false)}>
            {language['CANCEL']}
          </BaseButton>,
        ]}
        onClose={() => setIsModalVisible(false)}>
        <div className={styles.label}>{language['BUSINESS_HALL']}</div>
        <div className={styles.value}>
          {[lounge?.iataCode, lounge?.terminalName, lounge?.loungeName]
            .filter((el) => !!el)
            .join(' — ')}
        </div>
        <div className={styles.label}>{language['USER']}</div>
        {users.map((user, index) => (
          <div key={index} className={styles.value}>
            {[user.firstname, user.surname].filter((_) => !!_).join(' ')}
            <br />
            {user.phoneNumber}
            <br />
            {user.email}
            <br />
          </div>
        ))}
        <div className={styles.label}>{language['V_VISITORS']}</div>
        <div className={styles.value}>{adults}</div>
        <div className={styles.label}>{language['AMOUNT']}</div>
        <div className={styles.value}>
          {selectedProduct?.prepaid === false
            ? language['NO_PAYMENT_REQUIRED']
            : selectedCost?.amount
            ? formatCurrency(selectedCost?.amount)
            : language['UNKNOUN_FEM']}
        </div>
      </BaseBottomSheet>
    </div>
  );
};

export default PaymentMethod;
