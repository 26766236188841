import React, { ChangeEvent, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { InputBase } from '@mui/material';
import { selectCities } from '../../../../redux/loungeDataSlice';
import { useAppSelector } from '../../../../utils/hooks';
import styles from './CitySearch.module.css';
import { selectLanguage } from '../../../../redux/profileDataSlice';

interface Interface {
  onClose: () => void;
  onSelect: (id: string) => void;
}

const CitySearch = (props: Interface) => {
  const { onClose, onSelect } = props;

  const language = useAppSelector(selectLanguage);
  const cities = Object.entries(useAppSelector(selectCities));

  const [search, setSearch] = useState('');
  const handleSearch = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setSearch(event.target.value.toLowerCase());

  const filteredCities = cities
    .filter((_) => {
      if (search) {
        return _[1].toLowerCase().startsWith(search);
      }

      return true;
    })
    .sort((a, b) => (a[1] > b[1] ? 1 : -1));

  const handleSelect = (id: string) => {
    onSelect(id);
    onClose();
  };

  return (
    <div className={styles.city_select}>
      <div className={styles.city_toolbar}>
        <div onClick={onClose} className={styles.back}>
          <ArrowBackIosIcon />
        </div>
        <InputBase
          sx={{ flex: 1 }}
          autoFocus
          className={styles.input}
          onChange={handleSearch}
          placeholder={language['FIND_STRING']}
        />
      </div>
      <div className={styles.cities}>
        {filteredCities.map((_) => (
          <div key={_[0]} className={styles.city} onClick={() => handleSelect(_[0])}>
            {_[1]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CitySearch;
