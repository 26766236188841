import axios from 'axios';
import httpClient, { setAuthHeader } from '../http-client';
import { getLocalAuth, removeLocalAuth, setLocalAuth } from '../../utils/auth';
import { AUTH } from '../../utils/config';

interface TokenResponse {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  scope: string;
  session_state: string;
  token_type: string;
}

const AUTH_URL =
  (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_KEYCLOAK_HOST : '') +
  (process.env.REACT_APP_KEYCLOAK_ROUTE || '');

const fetchToken = async (username: string, password: string, abortSignal?: AbortSignal) => {
  const query = new URLSearchParams({
    grant_type: 'password',
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    username,
    password,
  });
  const response = await axios.post<TokenResponse>(
    `${AUTH_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
    query,
    {
      signal: abortSignal,
      withCredentials: true,
      httpsAgent: {
        rejectUnauthorized: false,
      },
    }
  );
  setLocalAuth(response.data);
  setAuthHeader(response.data.token_type, response.data.access_token);
  return response.data;
};

const fetchTokenViaCode = async (code: string, redirectUrl: string, abortSignal?: AbortSignal) => {
  const query = new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    code: code,
    redirect_uri: redirectUrl,
  });
  const response = await axios.post<TokenResponse>(
    `${AUTH_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
    query,
    { signal: abortSignal }
  );
  setLocalAuth(response.data);
  setAuthHeader(response.data.token_type, response.data.access_token);
  return response.data;
};

const refreshToken = async (token?: string, abortSignal?: AbortSignal) => {
  const authData = getLocalAuth();
  if (!authData?.refresh_token || token) {
    return null;
  }
  const query = new URLSearchParams({
    grant_type: 'refresh_token',
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    refresh_token: token || authData.refresh_token,
  });
  const response = await axios.post<TokenResponse>(
    `${AUTH_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
    query,
    {
      signal: abortSignal,
      withCredentials: true,
      httpsAgent: {
        rejectUnauthorized: false,
      },
    }
  );
  setLocalAuth(response.data);
  setAuthHeader(response.data.token_type, response.data.access_token);
  return response.data;
};

const revokeToken = (forceAuthPage = true) => {
  removeLocalAuth();
  httpClient.defaults.headers.common['Authorization'] = '';
  if (forceAuthPage) {
    window.location.assign(AUTH);
  }
};

export type { TokenResponse };
export { fetchToken, fetchTokenViaCode, refreshToken, revokeToken };
