import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IconButton, Skeleton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Carousel } from 'react-responsive-carousel';
import { LoungeService } from '../../components';
import BaseButton from '../../ui/BaseButton/BaseButton';
import { selectLanguage, selectProfile } from '../../redux/profileDataSlice';
import {
  loadLoungesAsync,
  loadServicesAsync,
  selectCities,
  selectLounges,
  selectLoungesLoading,
} from '../../redux/loungeDataSlice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { LOUNGE_ORDER, LOUNGES } from '../../utils/config';
import styles from './LoungeContainer.module.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.css';
import location from '../../static/location.svg';
import worktime from '../../static/worktime.svg';

const LoungeContainer = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  let history = useHistory();

  const loading = useAppSelector(selectLoungesLoading);
  const lounges = useAppSelector(selectLounges);
  const cities = useAppSelector(selectCities);
  const profile = useAppSelector(selectProfile);
  const language = useAppSelector(selectLanguage);

  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref && ref.current) {
      const computedStyle = getComputedStyle(ref.current);
      let elementWidth = ref.current.clientWidth;
      elementWidth -=
        parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
      setWidth(elementWidth);
    }
  }, []);

  useEffect(() => {
    profile && dispatch(loadLoungesAsync(profile?.language));
    profile && dispatch(loadServicesAsync(profile?.language));
  }, [profile]);

  const data = id ? lounges.find((_) => _.id === +id) : undefined;
  const workTime = data?.workTime.replace('-', ' — ');
  const cityName = (data && cities[data.cityCode]) ?? data?.cityCode;

  const handleGetAccessClick = () => {
    history.push(LOUNGE_ORDER.replace(':id', id));
  };

  return (
    <div className={styles.root} ref={ref}>
      {loading ? (
        <div>
          <Skeleton
            variant="rectangular"
            height={250}
            style={{ width: 'calc(100% + 32px)', margin: '0 -16px' }}
          />
          <Skeleton
            variant="rectangular"
            height={24}
            style={{ margin: '16px 0 6px', width: '60%' }}
          />
          <Skeleton variant="rectangular" height={14} style={{ marginBottom: 2, width: '40%' }} />
        </div>
      ) : data ? (
        <>
          <IconButton classes={{ root: styles.back }} onClick={() => history.push(LOUNGES)}>
            <ArrowBackIosIcon />
          </IconButton>
          <div className={styles.image_container}>
            <Carousel
              autoPlay={false}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              infiniteLoop={true}
              emulateTouch={true}>
              {data.images && data.images.length
                ? data.images.map((_) => (
                    <div key={_} className={styles.image}>
                      <img
                        src={`/files${_}`}
                        alt=""
                        style={{
                          ...(width ? { height: `calc(${width}px / 1.44)` } : {}),
                        }}
                      />
                    </div>
                  ))
                : [
                    <div className={styles.image}>
                      <img
                        src={`/files${data.thumbnail}`}
                        alt=""
                        style={{
                          ...(width ? { height: `calc(${width}px / 1.44)` } : {}),
                        }}
                      />
                    </div>,
                  ]}
            </Carousel>
          </div>
          <div className={styles.lounge}>{data.loungeName}</div>
          <div className={styles.airport}>
            {cityName}, {data.airportName}
          </div>
          <div className={styles.airport}>{data.terminalName}</div>
          <div className={styles.location_worktime}>
            {data.location ? (
              <div className={styles.location_worktime_item}>
                <img src={location} alt="" />
                <div>{data.location}</div>
              </div>
            ) : null}
            {workTime || data.loungeState === 'not_work' ? (
              <div
                className={`${styles.location_worktime_item} ${
                  data.loungeState === 'not_work' ? styles.maintenance : ''
                }`}>
                <img src={worktime} alt="" />
                <div>
                  {data.loungeState === 'not_work' ? language['LOUNGE_MAINTENANCE'] : workTime}
                </div>
              </div>
            ) : null}
          </div>
          {data.loungeServices ? (
            <div className={styles.services}>
              <div className={styles.title}>{language['SERV']}</div>
              {data.loungeServices.map((item) => (
                <LoungeService key={item} data={item} />
              ))}
            </div>
          ) : null}
          {data.conditions ? (
            <div className={styles.terms}>
              <div className={styles.title}>{language['CONDITIONS']}</div>
              <div className={styles.terms_text}>{data.conditions}</div>
            </div>
          ) : null}
          {data.description ? (
            <div className={styles.desc}>
              <div className={styles.title}>{language['DESC']}</div>
              <div className={styles.terms_text}>{data.description}</div>
            </div>
          ) : null}
          {data.loungeState !== 'not_work' && (
            <div className={styles.actionButton}>
              <BaseButton onClick={handleGetAccessClick}>{language['ACCESS_BUTTON']}</BaseButton>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default LoungeContainer;
