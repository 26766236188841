import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VisitModel } from '../models';
import { AppDispatch, RootState } from './store';
import httpClient from '../api/http-client';

interface InitialState {
  loading: boolean;
  data: VisitModel[];
}

export const visitDataSlice = createSlice({
  name: 'visitData',
  initialState: {
    loading: false,
    data: [],
  } as InitialState,
  reducers: {
    setData: (state, action: PayloadAction<VisitModel[]>) => {
      state.data = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setData, setLoading } = visitDataSlice.actions;

export const loadVisitsAsync =
  (lang = 'ru') =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await httpClient.get(`visits?lang=${lang}`);
      dispatch(setData(response.data));
    } catch (e: any) {
      console.log('loadVisitsAsync', e.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const selectVisits = (state: RootState) => state.visitData.data;
export const selectVisitsLoading = (state: RootState) => state.visitData.loading;

export default visitDataSlice.reducer;
