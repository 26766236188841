import { FC, ReactNode } from 'react';
import styles from './ActivityList.module.css';
import chair from '../../static/chair.svg';
import { useAppSelector } from '../../utils/hooks';
import { selectLanguage } from '../../redux/profileDataSlice';
import { BaseAccordion } from '../../ui';

interface ActivityListProps {
  label: string;
  children?: ReactNode;
  onLabelClick?: () => void;
}

const ActivityList: FC<ActivityListProps> = ({ label, children, onLabelClick }) => {
  const language = useAppSelector(selectLanguage);

  const noActivity = Array.isArray(children) && !children.length;

  return (
    <div>
      {noActivity ? (
        <div className={styles.emptyContainer}>
          <div className={styles.emptyImage}>
            <img src={chair} alt="" />
          </div>
          <div className={styles.emptyTitle}>{language['STILL_EMPTY']}</div>
          <div className={styles.emptySubtitle}>{language['VISIT_HISTORY_HERE']}</div>
        </div>
      ) : (
        <BaseAccordion
          title={
            <div className={styles.header} onClick={onLabelClick}>
              <div className={styles.label}>{label}</div>
            </div>
          }
          defaultExpanded
          paleExpandIcon
          disableHorizontalPadding
          collapsedArrowDegree={-90}
          expandedArrowDegree={0}
          borderless>
          <div className={styles.items}>{children}</div>
        </BaseAccordion>
      )}
    </div>
  );
};

export default ActivityList;
