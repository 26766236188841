import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortOrder } from '../utils/Constants';
import { RootState } from './store';

interface Filters {
  city: string;
  airports: string[];
  services: string[];
}

interface Interface {
  filter: Filters;
  sortOrder: number;
}

export const loungePageSlice = createSlice({
  name: 'loungePage',
  initialState: {
    filter: {
      city: '',
      airports: [],
      services: [],
    },
    sortOrder: SortOrder.desc,
  } as Interface,
  reducers: {
    setSortOrder: (state, action: PayloadAction<number>) => {
      state.sortOrder = action.payload;
    },
    setFilters: (state, action: PayloadAction<Filters>) => {
      state.filter = {
        city: action.payload.city,
        airports: action.payload.airports,
        services: action.payload.services,
      };
    },
    resetLoungeFilterSortOrder: (state) => {
      state.filter = {
        city: '',
        airports: [],
        services: [],
      };
      state.sortOrder = SortOrder.desc;
    },
  },
});

export const { setSortOrder, setFilters, resetLoungeFilterSortOrder } = loungePageSlice.actions;

export const selectLoungeFilter = (state: RootState) => state.loungePage.filter;
export const selectLoungeSortOrder = (state: RootState) => state.loungePage.sortOrder;

export default loungePageSlice.reducer;
