import React, { useState } from 'react';
import { Chip } from '@mui/material';
import { useAppSelector } from '../../../../utils/hooks';
import { selectServices } from '../../../../redux/loungeDataSlice';
import { selectLanguage } from '../../../../redux/profileDataSlice';
import styles from './Services.module.css';

interface Props {
  filterServices: string[];
  onChange: (id: string) => void;
}

const Services = (props: Props) => {
  const { filterServices, onChange } = props;

  const services = Object.entries(useAppSelector(selectServices));
  const language = useAppSelector(selectLanguage);

  const [viewAllServices, setVIewAllServices] = useState(false);
  const handleToggleView = () => setVIewAllServices(!viewAllServices);

  return (
    <div className={styles.extra}>
      <div className={styles.filter_title}>{language['ADTNL_FILTER']}</div>
      <div className={`${styles.services} ${viewAllServices ? styles.services_all : ''}`}>
        {services.map((_) => (
          <Chip
            key={_[0]}
            variant={filterServices.includes(_[0]) ? 'filled' : 'outlined'}
            classes={{ filled: styles.filled, outlined: styles.outlined }}
            onClick={() => onChange(_[0])}
            label={_[1]}
          />
        ))}
      </div>
      <div className={styles.services_btn} onClick={handleToggleView}>
        {viewAllServices ? language['HIDE_ALL'] : language['SHOW_ALL']}
      </div>
    </div>
  );
};

export default Services;
