import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { differenceInHours, format } from 'date-fns';
import { ArrowForwardRounded, CheckRounded, ContentCopyRounded } from '@mui/icons-material';
import styles from './Order.module.css';
import BaseHeader from '../../ui/BaseHeader';
import { numberWithSpaces } from '../../utils/NumberUtils';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { loadCardsAsync, loadOrdersAsync, selectCards, selectOrders } from '../../redux/cardDataSlice';
import BaseHeaderButton from '../../ui/BaseHeaderButton';
import {
  getPayments,
  OrderState,
  OrderType,
  PaymentStatus,
  selectOrderState,
  setOrder,
  setOrderAdults,
  setOrderUsers,
} from '../../redux/loungeOrderPageSlice';
import { selectLanguage, selectLanguageComment } from '../../redux/profileDataSlice';
import { UnpaidCard } from '../../components';
import { PAYMENT_METHOD } from '../../utils/config';
import { loadLoungesAsync, selectLounges } from '../../redux/loungeDataSlice';

const OrderContainer = () => {
  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const dispatch = useAppDispatch();
  const orders = useAppSelector(selectOrders);
  const lounges = useAppSelector(selectLounges);
  const language = useAppSelector(selectLanguage);
  const orderStateEnum = useAppSelector(selectOrderState);
  const languageComment = useAppSelector(selectLanguageComment);
  const order = useAppSelector((state) =>
    selectOrders(state).find((order) => String(order.orderId) === orderId)
  );
  const products = useAppSelector(selectCards);
  const product = useAppSelector((state) =>
    selectCards(state).find((card) => card.productId === order?.productUuid)
  );
  const lounge = lounges.find((_) => _.loungeUuid === order?.loungeUuid);
  const [initializing, setInitializing] = useState(false);

  const initialize = async () => {
    setInitializing(true);
    if (!lounges.length) {
      await dispatch(loadLoungesAsync());
    }
    if (!orders.length) {
      await dispatch(loadOrdersAsync());
    }
    if (!products.length) {
      await dispatch(loadCardsAsync());
    }
    setInitializing(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  const DEFAULT_SUBTITLE = languageComment[order && order.orderType ? order.orderType : 'GWP'];
  const [subtitle, setSubtitle] = useState(DEFAULT_SUBTITLE);
  const copyText = async (value: string) => {
    if (!navigator.clipboard && !window.isSecureContext) return;
    try {
      await navigator.clipboard.writeText(value);
      setSubtitle(language['CODE_COPIED']);
      setTimeout(() => setSubtitle(DEFAULT_SUBTITLE), 1500);
    } catch (e) {
      return;
    }
  };

  const handlePayClick = async () => {
    if (!order) {
      return;
    }
    const hoursPassed = differenceInHours(new Date(), new Date(order.createdAt));
    const shouldMakeNewPayment = hoursPassed > 23;
    if (shouldMakeNewPayment) {
      dispatch(
        setOrderUsers([
          {
            firstname: order.firstname,
            surname: order.surname,
            phoneNumber: order.phone,
            email: order.email,
          },
        ])
      );
      dispatch(setOrderAdults(order.adults));
      dispatch(setOrder(order));
      if (lounge) {
        history.push(PAYMENT_METHOD.replace(':id', String(lounge.id)));
      } else {
        alert('Бизнес-зал заказа не обнаружен');
      }
    } else {
      const payments = await dispatch(getPayments(order.orderId));
      const payment = payments?.find((payment) => payment.status === PaymentStatus.New);
      if (payment) {
        window.location.href = payment.url;
      } else {
        alert('Счет на оплату не найден');
      }
    }
  };

  const getMarkIcon = (status?: OrderState) => {
    switch (status) {
      case OrderState.Paid:
      case OrderState.NoPaymentRequired:
        return <CheckRounded />;
      default:
        return <></>;
    }
  };

  const getMarkStyle = (status?: OrderState) => {
    switch (status) {
      case OrderState.Paid:
      case OrderState.NoPaymentRequired:
        return styles.paidMark;
      case OrderState.Unpaid:
        return styles.unPaidMark;
      case OrderState.Completed:
      case OrderState.Cancelled:
      case OrderState.PartiallyCompleted:
        return styles.mark;
      default:
        return '';
    }
  };

  return (
    <div className={styles.root}>
      <BaseHeader
        title={order?.accessCode ? numberWithSpaces(order.accessCode) : '**********'}
        subtitle={subtitle}
        actions={
          order?.accessCode ? (
            <BaseHeaderButton type="circle" onClick={() => copyText(order.accessCode)}>
              <ContentCopyRounded style={{ height: 18 }} />
            </BaseHeaderButton>
          ) : undefined
        }
        showBack>
        {order?.orderState && orderStateEnum && (
          <div className={getMarkStyle(order?.orderState)}>
            {getMarkIcon(order?.orderState)}
            {orderStateEnum[order.orderState]}
          </div>
        )}
        <div style={{ height: order?.orderState === OrderState.Paid ? 85 : 90 }}></div>
      </BaseHeader>
      {order && product && (
        <>
          {order.accessCode && (
            <div className={styles.qr}>
              <QRCode value={order.accessCode} size={250} />
            </div>
          )}
          {order.orderState === OrderState.Unpaid && (
            <UnpaidCard
              style={{ position: 'sticky', margin: '-75px 16px 8px' }}
              loading={initializing}
              disabled={initializing}
              onPayClick={handlePayClick}
            />
          )}
          <div
            className={styles.container}
            style={{ opacity: order.orderState === OrderState.Unpaid ? 0.4 : 1 }}>
            {order.startDate && (
              <div className={styles.item}>
                <div className={styles.label}>{language['VALIDITY_PERIOD']}</div>
                <div className={styles.value}>{`${language['PERIOD_FROM']} ${format(
                  new Date(order.startDate),
                  'dd.MM.yyyy'
                )}`}</div>
              </div>
            )}
            <div className={styles.item}>
              <div className={styles.label}>{language['NAME_SURNAME']}</div>
              <div className={styles.value}>
                {[order.firstname, order.surname].filter((_) => !!_).join(' ')}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.label}>{language['PH_NUM']}</div>
              <div className={styles.value}>{order.phone}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.label}>{language['PASS_NUM_ORDER']}</div>
              <div className={styles.value}>{order.adults}</div>
            </div>
            {order.orderType === OrderType.GreyWallPass && (
              <>
                <div className={styles.item}>
                  <div className={styles.label}>{language['BUSINESS_HALL']}</div>
                  <div className={styles.value}>{order.loungeName}</div>
                </div>
                <div className={styles.item}>
                  <div className={styles.label}>{language['PRODUCT_OF_BANK']}</div>
                  <div className={styles.value}>
                    {[product.productName, product.bankName].filter((_) => !!_).join(', ')}
                  </div>
                </div>
              </>
            )}
            {order.orderType === OrderType.DreamFolks && (
              <>
                <div className={styles.item}>
                  <div className={styles.label}>{language['ORDER_URL_HEADER']}</div>
                  <div className={`${styles.value} ${styles.link}`}>
                    <a href={order.url}>{language['BTN_GO']}</a>
                    <ArrowForwardRounded />
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default OrderContainer;
