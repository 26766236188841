import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from './store';
import { SupportModel } from '../models';
import httpClient from '../api/http-client';

interface Interface {
  loading: boolean;
  isSend: boolean | undefined;
}

export const supportDataSlice = createSlice({
  name: 'supportData',
  initialState: {
    loading: false,
    isSend: undefined,
  } as Interface,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsSendSupport: (state, action: PayloadAction<boolean | undefined>) => {
      state.isSend = action.payload;
    },
  },
});

export const { setLoading, setIsSendSupport } = supportDataSlice.actions;

export const sendSupportAsync = (data: SupportModel) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  try {
    await httpClient.post(`support/email`, data);
    dispatch(setIsSendSupport(true));
  } catch (e: any) {
    console.log('sendSupportAsync', e.message);
    dispatch(setIsSendSupport(false));
  } finally {
    dispatch(setLoading(false));
  }
};

export const selectLoadingSupport = (state: RootState) => state.supportData.loading;
export const selectIsSendSupport = (state: RootState) => state.supportData.isSend;

export default supportDataSlice.reducer;
