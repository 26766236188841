import { useParams } from 'react-router-dom';
import styles from './ProductInfoContainer.module.css';
import BaseHeader from '../../ui/BaseHeader';
import { useAppSelector } from '../../utils/hooks';
import { selectCards } from '../../redux/cardDataSlice';
import { selectLanguage } from '../../redux/profileDataSlice';

const ProductInfoContainer = () => {
  const { productId } = useParams<{ productId: string }>();
  const language = useAppSelector(selectLanguage);
  const product = useAppSelector((state) =>
    selectCards(state).find((card) => card.productId === productId)
  );

  return (
    <div className={styles.root}>
      <BaseHeader title={language['COMPEN_PROG']} titleSmall showBack />
      <div className={styles.container}>
        {product?.description && (
          <>
            <div className={styles.sectionTitle}>{language['DESC_PROG']}</div>
            <div className={styles.sectionContent}>{product?.description}</div>
          </>
        )}
        {product?.rules && (
          <>
            <div className={styles.sectionTitle}>{language['USE_RULES']}</div>
            <div className={styles.sectionContent}>{product?.rules}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductInfoContainer;
