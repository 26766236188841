import React, { ChangeEvent, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';
import { Input, InputTitle } from '../../../components';
import { selectLanguage } from '../../../redux/profileDataSlice';
import { selectCodeId, setUserData } from '../../../redux/registrationDataSlice';
import { transliteration } from '../../../utils/TextUtils';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import styles from './Registration.module.css';

interface Interface {
  onSelectAuth: (type: string) => void;
}

const Registration = ({ onSelectAuth }: Interface) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const codeId = useAppSelector(selectCodeId);

  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [patronymic, setPatronymic] = useState('');
  const [latinFirstname, setLatinFirstname] = useState('');
  const [latinSurname, setLatinSurname] = useState('');
  const [latinPatronymic, setLatinPatronymic] = useState('');
  const [email, setEmail] = useState('');

  const handleChangeFirstname = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value.trim();
    setFirstname(value);
    setLatinFirstname(transliteration(value));
  };
  const handleChangeSurname = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value.trim();
    setSurname(value);
    setLatinSurname(transliteration(value));
  };
  const handleChangePatronymic = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value.trim();
    setPatronymic(value);
    setLatinPatronymic(transliteration(value));
  };
  const handleChangeLatinFirstname = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setLatinFirstname(event.target.value);
  const handleChangeLatinSurname = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setLatinSurname(event.target.value);
  const handleChangeLatinPatronymic = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setLatinPatronymic(event.target.value);
  const handleChangeEmail = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setEmail(event.target.value);

  const handleSetPassword = () => {
    const invalidFields: string[] = [];
    if (!firstname.trim() || !latinFirstname.trim()) {
      invalidFields.push(language['NAME']);
    }
    if (!surname.trim() || !latinSurname.trim()) {
      invalidFields.push(language['SURNAME']);
    }
    if (!email.trim()) {
      invalidFields.push('E-mail');
    }
    if (invalidFields.length) {
      alert(`${language['FILL_FIELDS']}: ${invalidFields.join(', ')}`);
      return;
    }
    const phone = window.localStorage.getItem('gwp_username') || '';
    dispatch(
      setUserData({
        phone,
        password: '',
        codeId,
        firstname,
        surname,
        patronymic,
        email,
        latinFullname: `${latinSurname} ${latinFirstname} ${latinPatronymic}`,
        latinFirstname,
        latinSurname,
        latinPatronymic,
      })
    );
    onSelectAuth('password');
  };

  return (
    <div className={styles.root}>
      <div>
        <div onClick={() => onSelectAuth('manual')} className={styles.back}>
          <ArrowBackIosIcon /> {language['GOBACK_LINK']}
        </div>
        <div className={styles.title}>{language['REGISTR']}</div>
        <>
          <div className={styles.two_column}>
            <InputTitle style={{ flex: 1 }} title={language['NAME']} required />
            <InputTitle style={{ flex: 1 }} title={'First Name'} required />
          </div>
          <div className={styles.two_column}>
            <Input
              autoFocus
              error={!firstname}
              onChange={handleChangeFirstname}
              placeholder={language['NAME']}
              value={firstname}
            />
            <Input
              autoFocus
              error={!latinFirstname}
              onChange={handleChangeLatinFirstname}
              placeholder={'First Name'}
              value={latinFirstname}
            />
          </div>
          <div className={styles.two_column}>
            <InputTitle style={{ flex: 1 }} title={language['SURNAME']} required />
            <InputTitle style={{ flex: 1 }} title={'Last Name'} required />
          </div>
          <div className={styles.two_column}>
            <Input
              autoFocus
              error={!surname}
              onChange={handleChangeSurname}
              placeholder={language['SURNAME']}
              value={surname}
            />
            <Input
              autoFocus
              error={!latinSurname}
              onChange={handleChangeLatinSurname}
              placeholder={'Last Name'}
              value={latinSurname}
            />
          </div>
          <div className={styles.two_column}>
            <InputTitle style={{ flex: 1 }} title={language['PATRONYMIC']} required />
            <InputTitle style={{ flex: 1 }} title={'Middle Name'} required />
          </div>
          <div className={styles.two_column}>
            <Input
              onChange={handleChangePatronymic}
              placeholder={language['PATRONYMIC']}
              value={patronymic}
            />
            <Input
              onChange={handleChangeLatinPatronymic}
              placeholder={'Middle Name'}
              value={latinPatronymic}
            />
          </div>
          <InputTitle title={'E-mail'} />
          <Input error={!email} onChange={handleChangeEmail} placeholder={'E-mail'} value={email} />
        </>
      </div>
      <div>
        <Button
          variant="contained"
          classes={{ root: styles.button }}
          onClick={handleSetPassword}
          fullWidth>
          {language['BTN_REGISTR']}
        </Button>
        <div className={styles.text}>
          {language['ACCEPT_PHRASE_1']}{' '}
          <a
            href="https://www.africau.edu/images/default/sample.pdf"
            target="_blank"
            className={styles.link}>
            {language['ACCEPT_PHRASE_2']}
          </a>
          , {language['ACCEPT_PHRASE_3']}{' '}
          <a
            href="https://www.africau.edu/images/default/sample.pdf"
            target="_blank"
            className={styles.link}>
            {language['ACCEPT_PHRASE_4']}
          </a>{' '}
          {language['ACCEPT_PHRASE_5']}{' '}
          <a
            href="https://www.africau.edu/images/default/sample.pdf"
            target="_blank"
            className={styles.link}>
            {language['ACCEPT_PHRASE_6']}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Registration;
