import { Redirect, Route, Switch } from 'react-router-dom';
import ProfileEditContainer from '../ProfileEdit/ProfileEditContainer';
import ProfileEditEmailContainer from '../ProfileEdit/ProfileEditEmailContainer';
import ProfileEditPhoneContainer from '../ProfileEdit/ProfileEditPhoneContainer';
import { NavigationBottom } from '../../components';
import {
  AboutContainer,
  LoungeContainer,
  LoungeOrder,
  LoungesContainer,
  LoungesFilterContainer,
  MainContainer,
  ProfileContainer,
  SupportContainer,
} from '../index';
import { CircularProgress, Container } from '@mui/material';
import { selectLoading, selectLoadingLanguage } from '../../redux/profileDataSlice';
import { useAppSelector } from '../../utils/hooks';
import {
  ABOUT,
  LOUNGE_ID,
  LOUNGE_ORDER,
  LOUNGES,
  LOUNGES_FILTER,
  MAIN,
  ORDER,
  PAYMENT_METHOD,
  PRODUCT_INFO,
  PROFILE,
  PROFILE_EDIT,
  PROFILE_EDIT_EMAIL,
  PROFILE_EDIT_PHONE,
  SUPPORT,
} from '../../utils/config';
import styles from './PrivateRoutes.module.css';
import PaymentMethod from '../PaymentMethod';
import ProductInfoContainer from '../ProductInfo';
import OrderContainer from '../Order';

const PrivateRoutes = () => {
  const loading = useAppSelector(selectLoading);
  const loadingLanguage = useAppSelector(selectLoadingLanguage);

  return (
    <Container maxWidth="sm" className={styles.container} id="container">
      {loading || loadingLanguage ? (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Switch>
            <Route exact path={MAIN} component={MainContainer} />
            <Route exact path={PRODUCT_INFO} component={ProductInfoContainer} />
            <Route exact path={ORDER} component={OrderContainer} />
            <Route exact path={LOUNGES} component={LoungesContainer} />
            <Route exact path={LOUNGES_FILTER} component={LoungesFilterContainer} />
            <Route exact path={LOUNGE_ID} component={LoungeContainer} />
            <Route exact path={LOUNGE_ORDER} component={LoungeOrder} />
            <Route exact path={PAYMENT_METHOD} component={PaymentMethod} />
            <Route exact path={PROFILE} component={ProfileContainer} />
            <Route exact path={PROFILE_EDIT} component={ProfileEditContainer} />
            <Route exact path={PROFILE_EDIT_EMAIL} component={ProfileEditEmailContainer} />
            <Route exact path={PROFILE_EDIT_PHONE} component={ProfileEditPhoneContainer} />
            <Route exact path={SUPPORT} component={SupportContainer} />
            <Route exact path={ABOUT} component={AboutContainer} />
            <Redirect to={MAIN} />
          </Switch>
          <NavigationBottom />
        </>
      )}
    </Container>
  );
};

export default PrivateRoutes;
