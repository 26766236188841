import React from 'react';
import { useAppSelector } from '../../utils/hooks';
import { selectLanguage } from '../../redux/profileDataSlice';
import styles from './AboutContainer.module.css';
import { BaseHeader } from '../../ui';

const AboutContainer = () => {
  const language = useAppSelector(selectLanguage);

  return (
    <>
      <BaseHeader title={language['ABOUT_APP_1']} showBack solidBackground />
      <div className={styles.root}>
        <div className={styles.text}>{language['ABOUT_APP_2']}</div>
        <div className={styles.sign}>{language['ABOUT_APP_3']}</div>
      </div>
    </>
  );
};

export default AboutContainer;
