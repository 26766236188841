import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Input, InputTitle } from '../../components';
import { BaseAccordion, BaseButton, BaseCounter, BaseHeader, BasePhoneInput } from '../../ui';
import { loadLoungesAsync, loadServicesAsync, selectLounges } from '../../redux/loungeDataSlice';
import { setOrderAdults, setOrderUsers } from '../../redux/loungeOrderPageSlice';
import { selectLanguage, selectLanguageComment, selectLanguageDesc, selectProfile } from '../../redux/profileDataSlice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { PAYMENT_METHOD } from '../../utils/config';
import styles from './LoungeOrderContainer.module.css';

interface User {
  firstname: string;
  surname: string;
  phoneNumber: string;
  email: string;
}

const LoungeOrderContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const language = useAppSelector(selectLanguage);
  const languageComment = useAppSelector(selectLanguageComment);
  const languageDesc = useAppSelector(selectLanguageDesc);
  const profile = useAppSelector(selectProfile);
  const lounges = useAppSelector(selectLounges);

  const data = id ? lounges.find((_) => _.id === +id) : undefined;

  useEffect(() => {
    profile && dispatch(loadLoungesAsync(profile?.language));
    profile && dispatch(loadServicesAsync(profile?.language));
  }, [profile]);

  const isFormDisabled = data?.loungeOwner === 'GWP';
  const [users, setUsers] = useState<User[]>([
    {
      firstname: profile ? profile.firstname : '',
      surname: profile ? profile.surname : '',
      phoneNumber: profile
        ? (profile.phoneList.find((_) => _.primary) || profile.phoneList[0]).phoneNumber
        : '',
      email: profile ? profile.emails[0].email : '',
    },
  ]);
  const [currentExpanded, setCurrentExpanded] = useState<null | number>(0);
  const [adults, setAdults] = useState(1);

  const handleAddVoucher = () => {
    const newUser: User = {
      firstname: '',
      surname: '',
      phoneNumber: '',
      email: '',
    };
    setCurrentExpanded(users.length);
    setUsers((u) => [...u, newUser]);
  };

  const handleRemoveVoucher = (index: number) => {
    setUsers((prevUsers) => prevUsers.filter((u, i) => i !== index));
  };

  const updateUser = (index: number, key: string, value: string) => {
    setUsers((prevUsers) =>
      prevUsers.map((user, i) => (i === index ? { ...user, [key]: value } : user))
    );
  };

  const continueToPaymentMethod = () => {
    dispatch(setOrderUsers(users));
    dispatch(setOrderAdults(data?.loungeOwner === 'DF' ? users.length : adults));
    history.push(PAYMENT_METHOD.replace(':id', id));
  };

  const renderForm = (user: User, i: number) => {
    return (
      <>
        <InputTitle title={language['NAME']} />
        <Input
          placeholder={language['NAME']}
          value={user.firstname}
          disabled={isFormDisabled}
          onChange={(e) => updateUser(i, 'firstname', e.target.value)}
        />
        <InputTitle title={language['SURNAME']} />
        <Input
          placeholder={language['SURNAME']}
          value={user.surname}
          disabled={isFormDisabled}
          onChange={(e) => updateUser(i, 'surname', e.target.value)}
        />
        <InputTitle title={language['PH_NUM']} />
        <BasePhoneInput
          value={user.phoneNumber}
          disabled={isFormDisabled}
          onChange={(value) => updateUser(i, 'phoneNumber', value)}
        />
        <InputTitle title={'E-mail'} />
        <Input
          placeholder={'E-mail'}
          value={user.email}
          disabled={isFormDisabled}
          onChange={(e) => updateUser(i, 'email', e.target.value)}
        />
      </>
    );
  };

  const renderMultipleVisitorsAction = () => {
    if (data?.loungeOwner === 'DF') {
      // TODO: Скрыть возможность добавить ваучер для Dream Folks залов, пока не исправлен бэкенд
      return null;
      return (
        <div className={styles.changeVoucher} onClick={handleAddVoucher}>
          <span>{language['ADD_VOUCHER']}</span>
          <ArrowForwardIosIcon />
        </div>
      );
    } else {
      return (
        <div className={styles.visitorsCounter}>
          <div className={styles.visitorsCounterLabel}>{language['NUM_VISIT']}</div>
          <BaseCounter value={adults} onChange={setAdults} min={1} max={10} />
        </div>
      );
    }
  };

  return (
    <div>
      <BaseHeader
        title={language['ORDER_DETAILS']}
        subtitle={data?.loungeOwner ? languageComment[data?.loungeOwner] : ''}
        showBack
        onBack={history.goBack}
      />
      {users.map((user, i) =>
        users.length > 1 ? (
          <BaseAccordion
            key={i}
            title={`${user.firstname} ${user.surname}`}
            expanded={currentExpanded === i}
            onChange={(expanded) => setCurrentExpanded(expanded ? i : null)}>
            {renderForm(user, i)}
            <div
              className={`${styles.changeVoucher} ${styles.danger}`}
              onClick={() => handleRemoveVoucher(i)}>
              <span>{language['DEL_VOUCHER']}</span>
            </div>
          </BaseAccordion>
        ) : (
          <div key={i} className={styles.container}>
            {renderForm(user, i)}
          </div>
        )
      )}
      {renderMultipleVisitorsAction()}
      <div className={styles.footer}>{data?.loungeOwner ? languageDesc[data?.loungeOwner] : ''}</div>
      <div className={styles.action}>
        <BaseButton onClick={continueToPaymentMethod}>{language['GO_BUTTON_1']}</BaseButton>
      </div>
    </div>
  );
};

export default LoungeOrderContainer;
