import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, InputBase } from '@mui/material';
import { loadUserProfileAsync, selectLanguage, selectProfile } from '../../redux/profileDataSlice';
import {
  selectIsSendSupport,
  selectLoadingSupport,
  sendSupportAsync,
  setIsSendSupport,
} from '../../redux/supportDataSlice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { PROFILE } from '../../utils/config';
import styles from './SupportContainer.module.css';
import { BaseButton, BaseHeader } from '../../ui';

const SupportContainer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const language = useAppSelector(selectLanguage);
  const profile = useAppSelector(selectProfile);
  const loading = useAppSelector(selectLoadingSupport);
  const isSend = useAppSelector(selectIsSendSupport);

  useEffect(() => {
    !profile && dispatch(loadUserProfileAsync());

    return () => {
      dispatch(setIsSendSupport(undefined));
    };
  }, []);

  const [firstname, setFirstname] = useState(profile?.firstname);
  const [email, setEmail] = useState(profile?.emails[0].email);
  const [message, setMessage] = useState('');

  const handleChangeFirstname = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setFirstname(event.target.value);
  const handleChangeEmail = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setEmail(event.target.value);
  const handleChangeMessage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setMessage(event.target.value);

  const handleSubmit = () => {
    firstname &&
      email &&
      dispatch(
        sendSupportAsync({
          name: firstname,
          email,
          message,
        })
      );
  };

  return (
    <>
      <BaseHeader
        title={language['SUPPORT']}
        showBack
        solidBackground
        onBack={() => history.push(PROFILE)}
      />
      <div className={styles.root}>
        <div>
          {isSend ? (
            <Alert variant="outlined" severity="success" style={{ marginBottom: 15 }}>
              {language['MESSAGE_SENT']}
            </Alert>
          ) : null}
          {isSend === false ? (
            <Alert variant="outlined" severity="warning" style={{ marginBottom: 15 }}>
              {language['MESSAGE_DONT_SENT']}
            </Alert>
          ) : null}
          <div className={styles.input_title}>{language['NAME']}</div>
          <InputBase
            autoFocus
            fullWidth
            className={styles.input}
            error={!firstname}
            onChange={handleChangeFirstname}
            placeholder={language['NAME']}
            value={firstname}
          />
          <div className={styles.input_title}>E-mail</div>
          <InputBase
            fullWidth
            className={styles.input}
            error={!email}
            onChange={handleChangeEmail}
            placeholder="E-mail"
            value={email}
          />
          <div className={styles.input_title}>{language['MESSAGE_HEAD']}</div>
          <InputBase
            fullWidth
            multiline
            minRows={5}
            className={`${styles.input} ${styles.textarea}`}
            error={!message}
            onChange={handleChangeMessage}
            placeholder={language['MESSAGE_FIELD']}
            value={message}
          />
        </div>
        <BaseButton
          floating
          loading={loading}
          disabled={!firstname || !email || !message}
          onClick={handleSubmit}>
          {language['SEND_MESSAGE']}
        </BaseButton>
      </div>
    </>
  );
};

export default SupportContainer;
