import React from 'react';
import { useAppSelector } from '../../utils/hooks';
import { selectProfile } from '../../redux/profileDataSlice';
import EditEmail from '../../components/Profile/EditEmail';

const ProfileEditEmailContainer = () => {
  const profile = useAppSelector(selectProfile);

  return profile ? <EditEmail profile={profile} /> : null;
};

export default ProfileEditEmailContainer;
