import { FC, ReactNode } from 'react';
import styles from './BaseCard.module.css';

interface BaseCardProps {
  title?: ReactNode;
  right?: ReactNode;
  tags?: string[];
  subtitle?: ReactNode;
  onClick?: () => void;
}

const BaseCard: FC<BaseCardProps> = ({ title, right, tags, subtitle, onClick }) => {
  return (
    <div
      className={styles.card}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}>
      <div className={styles.top}>
        <div className={styles.row}>
          <div className={styles.title}>{title}</div>
          <div className={styles.right}>{right}</div>
        </div>
        {tags?.map((tag, i) => (
          <div key={i} className={styles.badge}>
            {tag}
          </div>
        ))}
      </div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  );
};

export default BaseCard;
