import { combineReducers } from 'redux';
import profileDataSlice from './profileDataSlice';
import cardDataSlice from './cardDataSlice';
import visitDataSlice from './visitDataSlice';
import visitPageSlice from './visitPageSlice';
import loungePageSlice from './loungePageSlice';
import loungeDataSlice from './loungeDataSlice';
import loungeOrderPageSlice from './loungeOrderPageSlice';
import registrationDataSlice from './registrationDataSlice';
import supportDataSlice from './supportDataSlice';
import userPhoneDataSlice from './userPhoneDataSlice';
import userEmailDataSlice from './userEmailDataSlice';
import productDataSlice from './productDataSlice';

export const rootReducer = combineReducers({
  profileData: profileDataSlice,
  cardData: cardDataSlice,
  visitData: visitDataSlice,
  visitPage: visitPageSlice,
  loungeData: loungeDataSlice,
  loungePage: loungePageSlice,
  loungeOrderPage: loungeOrderPageSlice,
  registrationData: registrationDataSlice,
  supportData: supportDataSlice,
  userPhoneData: userPhoneDataSlice,
  userEmailData: userEmailDataSlice,
  productData: productDataSlice,
});
