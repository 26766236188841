import styles from './UnpaidCard.module.css';
import check from '../../static/check.svg';
import { BaseButton } from '../../ui';
import { CSSProperties, FC } from 'react';
import { useAppSelector } from '../../utils/hooks';
import { selectLanguage } from '../../redux/profileDataSlice';

interface UnpaidCardProps {
  style?: CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  onPayClick?: () => void;
}

const UnpaidCard: FC<UnpaidCardProps> = ({ style, disabled, loading, onPayClick }) => {
  const language = useAppSelector(selectLanguage);

  return (
    <div className={styles.container} style={style}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <img src={check} alt="" />
        </div>
        <div>
          <div className={styles.title}>{language['ORDER_UNPAID']}</div>
          <div className={styles.subtitle}>{language['DONT_FOGET_PAY_ORDER']}</div>
        </div>
      </div>
      <div>
        <BaseButton disabled={disabled} loading={loading} onClick={onPayClick}>
          {language['PAY_ORDER']}
        </BaseButton>
      </div>
    </div>
  );
};

export default UnpaidCard;
