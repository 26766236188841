import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import styles from './BaseAccordion.module.css';
import { FC, ReactNode } from 'react';
import { ExpandMoreRounded } from '@mui/icons-material';

interface BaseAccordionProps {
  id?: string;
  title?: ReactNode;
  counter?: number;
  children?: ReactNode;
  expanded?: boolean;
  defaultExpanded?: boolean;
  borderless?: boolean;
  paleExpandIcon?: boolean;
  disableHorizontalPadding?: boolean;
  collapsedArrowDegree?: number;
  expandedArrowDegree?: number;
  onChange?: (expanded: boolean) => void;
}

const BaseAccordion: FC<BaseAccordionProps> = ({
  id,
  title,
  counter,
  children,
  expanded,
  defaultExpanded,
  borderless,
  paleExpandIcon,
  disableHorizontalPadding,
  collapsedArrowDegree,
  expandedArrowDegree,
  onChange,
}) => {
  return (
    <Accordion
      className={styles.container}
      style={{ border: borderless ? 'none' : undefined }}
      sx={{
        '.MuiAccordionSummary-root': {
          padding: disableHorizontalPadding ? '0 !important' : undefined,
        },
      }}
      disableGutters={true}
      elevation={0}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={(e, expanded) => onChange?.(expanded)}>
      <AccordionSummary
        sx={{
          '.MuiAccordionSummary-expandIconWrapper': {
            transform:
              collapsedArrowDegree !== undefined
                ? `rotate(${collapsedArrowDegree}deg) !important`
                : undefined,
          },
          '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform:
              expandedArrowDegree !== undefined
                ? `rotate(${expandedArrowDegree}deg) !important`
                : undefined,
          },
          svg: { fill: paleExpandIcon ? '#b8c1cc !important' : undefined },
        }}
        expandIcon={<ExpandMoreRounded />}
        id={id}>
        <div className={styles.title}>{title}</div>
        <div className={styles.counter}>{counter}</div>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: disableHorizontalPadding ? '0 !important' : undefined }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default BaseAccordion;
