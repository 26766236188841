import React, { ChangeEvent, useState } from 'react';
import { Input } from '../../../index';
import VerificationModal from '../../VerificationModal';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { UserPhoneModel } from '../../../../models';
import { checkUserPhoneAsync, selectLanguage } from '../../../../redux/profileDataSlice';
import { sendCodeAsync } from '../../../../redux/registrationDataSlice';
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks';

interface Interface {
  onClose: () => void;
  phone?: UserPhoneModel;
}

const EditModal = ({ onClose, phone }: Interface) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);

  const [number, setNumber] = useState(phone ? phone.phoneNumber : '');
  const [error, setError] = useState('');
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNumber(event.target.value);
    error && setError('');
  };

  const [viewVerification, setViewVerification] = useState(false);

  const handleSave = async () => {
    const userState = await dispatch(checkUserPhoneAsync(number));

    if (userState === 'REGISTERED') {
      setError(language['PHONENUMBER_ALREADY_REG']);
    } else {
      dispatch(sendCodeAsync(number));
      setViewVerification(true);
    }
  };

  const handleClose = () => onClose();

  return (
    <>
      <Dialog open={true} onClose={handleClose} fullWidth>
        <DialogTitle>
          {phone ? language['EDIT_PHONENUMBER'] : language['ADD_PHONENUMBER']}
        </DialogTitle>
        <DialogContent>
          <Input
            autoFocus
            error={!number}
            onChange={handleChange}
            placeholder={'7 (987) 654 3210'}
            value={number}
          />
          {error ? (
            <Alert variant="outlined" severity="warning">
              {error}
            </Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{language['CANCEL']}</Button>
          <Button onClick={handleSave} disabled={!number.length}>
            {language['SAVE_BUTTON']}
          </Button>
        </DialogActions>
      </Dialog>
      {viewVerification ? (
        <VerificationModal
          onClose={() => setViewVerification(false)}
          value={number}
          id={phone?.id}
        />
      ) : null}
    </>
  );
};

export default EditModal;
