import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CityModel, LoungesModel, ServiceModel } from '../models';
import httpClient from '../api/http-client';
import { AppDispatch, RootState } from './store';

interface InitialState {
  loading: boolean;
  data: LoungesModel[];
  cities: CityModel;
  services: ServiceModel;
}

export const loungeDataSlice = createSlice({
  name: 'loungeData',
  initialState: {
    loading: false,
    data: [],
    cities: {},
    services: {},
  } as InitialState,
  reducers: {
    setData: (state, action: PayloadAction<LoungesModel[]>) => {
      state.data = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCities: (state, action: PayloadAction<CityModel>) => {
      state.cities = action.payload;
    },
    setServices: (state, action: PayloadAction<ServiceModel>) => {
      state.services = action.payload;
    },
  },
});

export const { setData, setLoading, setCities, setServices } = loungeDataSlice.actions;

export const loadLoungesAsync =
  (lang = 'ru') =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await httpClient.get(`lounges?lang=${lang}`);
      dispatch(setData(response.data));
    } catch (e: any) {
      console.log('loadLoungesAsync', e.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const loadCitiesAsync =
  (lang = 'ru') =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await httpClient.get(`dictionary/cities?lang=${lang}`);
      dispatch(setCities(response.data));
    } catch (e: any) {
      console.log('loadCitiesAsync', e.message);
    }
  };

export const loadServicesAsync =
  (lang = 'ru') =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await httpClient.get(`dictionary/lounge_service?lang=${lang}`);

      if (response && response.status === 200) {
        dispatch(setServices(response.data));
      }
    } catch (e: any) {
      console.log('loadServicesAsync', e.message);
    }
  };

export const selectLounges = (state: RootState) => state.loungeData.data;
export const selectLoungesLoading = (state: RootState) => state.loungeData.loading;
export const selectCities = (state: RootState) => state.loungeData.cities;
export const selectServices = (state: RootState) => state.loungeData.services;

export default loungeDataSlice.reducer;
