import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from './store';
import httpClient from '../api/http-client';
import { UserPasswordUpdateModel, UserRegistrationModel } from '../models';

interface Interface {
  loadingSendCode: boolean;
  codeId: string;
  loadingCheckCode: boolean;
  loadingRegistration: boolean;
  userData: UserRegistrationModel;
  resetPassword: boolean;
  loadingUpdatePassword: boolean;
}

export const registrationDataSlice = createSlice({
  name: 'registrationData',
  initialState: {
    loadingSendCode: false,
    codeId: '',
    loadingCheckCode: false,
    loadingRegistration: false,
    userData: {},
    resetPassword: false,
    loadingUpdatePassword: false,
  } as Interface,
  reducers: {
    setLoadingSendCode: (state, action: PayloadAction<boolean>) => {
      state.loadingSendCode = action.payload;
    },
    setCodeId: (state, action: PayloadAction<string>) => {
      state.codeId = action.payload;
    },
    setLoadingCheckCode: (state, action: PayloadAction<boolean>) => {
      state.loadingCheckCode = action.payload;
    },
    setLoadingRegistration: (state, action: PayloadAction<boolean>) => {
      state.loadingRegistration = action.payload;
    },
    setUserData: (state, action: PayloadAction<UserRegistrationModel>) => {
      state.userData = action.payload;
    },
    setResetPassword: (state, action: PayloadAction<boolean>) => {
      state.resetPassword = action.payload;
    },
    setLoadingUpdatePassword: (state, action: PayloadAction<boolean>) => {
      state.loadingUpdatePassword = action.payload;
    },
  },
});

export const {
  setLoadingSendCode,
  setCodeId,
  setLoadingCheckCode,
  setLoadingRegistration,
  setUserData,
  setResetPassword,
  setLoadingUpdatePassword,
} = registrationDataSlice.actions;

export const sendCodeAsync =
  (phone: string, type: 'call' | 'email' = 'call') =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoadingSendCode(true));
    try {
      const response = await httpClient.post(`code/send?type=${type}&phone=${phone}`);

      dispatch(setCodeId(response.data));
    } catch (e: any) {
      console.log('sendCodeAsync', e.message);
    } finally {
      dispatch(setLoadingSendCode(false));
    }
  };

export const checkCodeAsync =
  (phone: string, code: string, codeId: string) => async (dispatch: AppDispatch) => {
    dispatch(setLoadingCheckCode(true));
    try {
      const response = await httpClient.post(`code/check`, {
        codeId,
        code,
        phone,
      });

      return response && response.status === 200 ? 'isCorrect' : 'noCorrect';
    } catch (e: any) {
      console.log('checkCodeAsync', e.message);

      return 'noCorrect';
    } finally {
      dispatch(setLoadingCheckCode(false));
    }
  };

export const registrationUserAsync =
  (data: UserRegistrationModel) => async (dispatch: AppDispatch) => {
    dispatch(setLoadingRegistration(true));
    try {
      const response = await httpClient.post(`user`, data);

      return response && response.status === 201;
    } catch (e: any) {
      console.log('registrationUserAsync', e.message);
      return false;
    } finally {
      dispatch(setLoadingRegistration(false));
    }
  };

export const updateUserPasswordAsync =
  (data: UserPasswordUpdateModel) => async (dispatch: AppDispatch) => {
    dispatch(setLoadingUpdatePassword(true));
    try {
      const response = await httpClient.put(`user`, data);

      return response && response.status === 200;
    } catch (e: any) {
      console.log('updateUserPasswordAsync', e.message);
      return false;
    } finally {
      dispatch(setLoadingUpdatePassword(false));
    }
  };

export const selectLoadingSendCode = (state: RootState) => state.registrationData.loadingSendCode;
export const selectCodeId = (state: RootState) => state.registrationData.codeId;
export const selectLoadingCheckCode = (state: RootState) => state.registrationData.loadingCheckCode;
export const selectLoadingRegistration = (state: RootState) =>
  state.registrationData.loadingRegistration;
export const selectUserData = (state: RootState) => state.registrationData.userData;
export const selectResetPassword = (state: RootState) => state.registrationData.resetPassword;
export const selectLoadingUpdatePassword = (state: RootState) =>
  state.registrationData.loadingUpdatePassword;

export default registrationDataSlice.reducer;
