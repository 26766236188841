import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from './store';
import { CardModel, LoungeOrderUserModel } from '../models';
import httpClient from '../api/http-client';
import { loadCardsAsync, selectCards } from './cardDataSlice';
import { selectCurrentLang } from './profileDataSlice';

export enum OrderType {
  GreyWallPass = 'GWP',
  DreamFolks = 'DREAM_FOLKS',
  LoungemeReseller = 'LOUNGEME_RESELLER',
}

export enum OrderState {
  Unpaid = 'UNPAID',
  Paid = 'PAID',
  NoPaymentRequired = 'NO_PAYMENT_REQUIRED',
  Completed = 'COMPLETED',
  PartiallyCompleted = 'COMPLETED_PARTLY',
  Cancelled = 'CANCELED',
}

export enum PaymentType {
  BankProduct = 'BANK_PRODUCT',
  TinkoffPay = 'TINKOFF_PAY',
  SBP = 'SBP',
  BankWidget = 'BANK_WIDGET',
}

export interface CostResponse {
  productUuid: string;
  price: number;
  adults: number;
  amount: number;
  discount: number;
  paymentType: PaymentType;
}

export interface OrderPayload {
  orderType: OrderType;
  idempotencyKey: string;
  productUuid: string;
  loungeUuid: string;
  firstname: string;
  surname: string;
  phone: string;
  email: string;
  adults: number;
  children: number;
  startDate?: string;
  endDate?: string;
}

export interface Order extends OrderPayload {
  orderId: number;
  loungeName: string;
  createdAt: string;
  userUuid: string;
  accessCode: string;
  url: string;
  amount: number;
  orderState: OrderState;
  iataCode: string;
}

export enum Platform {
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE',
}

export interface PaymentPayload {
  idempotencyKey: string;
  orderId: number;
  paymentType: PaymentType;
  platform: Platform;
  callbackUri: string;
}

export interface Payment {
  id: number;
  orderId: number;
  url: string;
  price: number;
  quantity: number;
  amount: number;
  discount: number;
  status: PaymentStatus;
}

export enum PaymentStatus {
  Init = 'INIT',
  New = 'NEW',
  FormShowed = 'FORM_SHOWED',
  Authorized = 'AUTHORIZED',
  Rejected = 'REJECTED',
  Confirmed = 'CONFIRMED',
  Cancelled = 'CANCELED',
  Refunded = 'REFUNDED',
  PartiallyRefunded = 'PARTIAL_REFUNDED',
}

interface LoungeOrderPageState {
  users: LoungeOrderUserModel[];
  adults: number;
  productCosts: { product: CardModel; costs: CostResponse[] }[];
  loadingProductCosts: boolean;
  order: Order | null;
  loadingCreateOrder: boolean;
  payment: Payment | null;
  orderState: Record<OrderState, string> | null;
}

export const loungeOrderPageSlice = createSlice({
  name: 'loungeOrderPage',
  initialState: {
    users: [],
    adults: 1,
    productCosts: [],
    loadingProductCosts: false,
    order: null,
    loadingCreateOrder: false,
    payment: null,
    orderState: null,
  } as LoungeOrderPageState,
  reducers: {
    setOrderUsers: (state, action: PayloadAction<LoungeOrderUserModel[]>) => {
      state.users = action.payload;
    },
    setOrderAdults: (state, action: PayloadAction<number>) => {
      state.adults = action.payload;
    },
    setOrderCosts: (state, action: PayloadAction<{ product: any; costs: CostResponse[] }[]>) => {
      state.productCosts = action.payload;
    },
    setLoadingOrderCosts: (state, action: PayloadAction<boolean>) => {
      state.loadingProductCosts = action.payload;
    },
    setOrder: (state, action: PayloadAction<Order | null>) => {
      state.order = action.payload;
    },
    setLoadingCreateOrder: (state, action: PayloadAction<boolean>) => {
      state.loadingCreateOrder = action.payload;
    },
    setPayment: (state, action: PayloadAction<Payment>) => {
      state.payment = action.payload;
    },
    setOrderState: (state, action: PayloadAction<Record<OrderState, string> | null>) => {
      state.orderState = action.payload;
    },
  },
});

export const loadOrderCosts =
  (loungeUuid: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoadingOrderCosts(true));
    const adults = selectLoungeOrderAdults(getState());
    await dispatch(loadCardsAsync());
    const products = selectCards(getState());
    const getQuery = (productUuid: string) =>
      new URLSearchParams({
        productUuid: productUuid,
        loungeUuid: loungeUuid,
        adults: adults.toString(),
      }).toString();

    const getCosts = async (productId: string) => {
      const result = await httpClient.get(`order/cost?${getQuery(productId)}`);

      return {
        productId,
        costs: result.data,
      }
    };

    const requests = products
      .filter((p) => p.prepaid)
      .map((p) => getCosts(p.productId));
    const results = await Promise.allSettled(requests);

    const productCosts = products
      .map((product) => {
        const result = results.find(_ => _?.status === 'fulfilled' &&
          _?.value?.productId === product.productId &&
          !!_?.value?.costs?.length);
        const noCosts = product.prepaid
          ? [{ paymentType: null, amount: null }]
          : [{ paymentType: null, amount: 0 }];
        const shouldAddCosts = !!product.prepaid && result;

        return {
          product: product,
          // @ts-ignore
          costs: shouldAddCosts ? result.value?.costs : noCosts,
        };
      });

    dispatch(setOrderCosts(productCosts as { product: any; costs: any }[]));
    dispatch(setLoadingOrderCosts(false));
  };

export const createOrder = (data: OrderPayload) => async (dispatch: AppDispatch) => {
  dispatch(setLoadingCreateOrder(true));
  try {
    const response = await httpClient.post(`order`, data);
    const order = response.data as Order;
    dispatch(setOrder(order));
    return order;
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(setLoadingCreateOrder(false));
  }
};

export const createOrderPayment = (data: PaymentPayload) => async (dispatch: AppDispatch) => {
  try {
    const response = await httpClient.post(`order/payment`, data);
    dispatch(setPayment(response.data));
  } catch (e) {
    console.error(e);
  }
};

export const getPayments = (orderId?: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await httpClient.get<Payment[]>(`order/payment`);
    return response.data.filter((payment) => (orderId ? payment.orderId === orderId : true));
  } catch (e) {
    console.error(e);
  }
};

export const loadOrderStateAsync =
  (langCode: string | null, signal?: AbortSignal) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const lang = langCode || selectCurrentLang(getState()) || 'ru';
    try {
      const response = await httpClient.get<Record<OrderState, string>>(
        `dictionary/order_state?lang=${lang}`,
        { signal }
      );
      dispatch(setOrderState(response.data));
    } catch (e: any) {
      console.log('loadOrderState', e.message);
    }
  };

export const {
  setOrderUsers,
  setOrderAdults,
  setOrderCosts,
  setLoadingOrderCosts,
  setOrder,
  setLoadingCreateOrder,
  setPayment,
  setOrderState,
} = loungeOrderPageSlice.actions;

export const selectLoungeOrderUsers = (state: RootState) => state.loungeOrderPage.users;
export const selectLoungeOrderAdults = (state: RootState) => state.loungeOrderPage.adults;
export const selectLoungeOrderCosts = (state: RootState) => state.loungeOrderPage.productCosts;
export const selectLoadingLoungeOrderCosts = (state: RootState) =>
  state.loungeOrderPage.loadingProductCosts;
export const selectLoungeOrder = (state: RootState) => state.loungeOrderPage.order;
export const selectLoadingCreateOrder = (state: RootState) =>
  state.loungeOrderPage.loadingCreateOrder;
export const selectLoungeOrderPayment = (state: RootState) => state.loungeOrderPage.payment;
export const selectOrderState = (state: RootState) => state.loungeOrderPage.orderState;

export default loungeOrderPageSlice.reducer;
