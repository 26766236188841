import { FC } from 'react';
import { format, lastDayOfMonth } from 'date-fns';
import { CardModel } from '../../models';
import styles from './ProductCard.module.css';
import tinkoffBrandText from '../../static/tinkoff_brand_text.svg';
import logo from '../../static/logo.png';
import { useAppSelector } from '../../utils/hooks';
import { selectLanguage } from '../../redux/profileDataSlice';

enum ProductCardAppearance {
  Tinkoff,
  GreyWallPass,
}

interface ProductCardProps {
  product: CardModel;
  appearance?: ProductCardAppearance;
  showGreyWallPassLogo?: boolean;
  onClick?: () => void;
}

const ProductCard: FC<ProductCardProps> = ({
  product,
  appearance = ProductCardAppearance.GreyWallPass,
  showGreyWallPassLogo,
  onClick,
}) => {
  const language = useAppSelector(selectLanguage);

  const getGradient = () => {
    switch (appearance) {
      case ProductCardAppearance.Tinkoff:
        return styles.tinkoffGradient;
      case ProductCardAppearance.GreyWallPass:
      default:
        return styles.defaultGradient;
    }
  };

  const getBranding = () => {
    switch (appearance) {
      case ProductCardAppearance.Tinkoff:
        return (
          <>
            <img src={tinkoffBrandText} alt="" />
            <h5>{product.productName}</h5>
          </>
        );
      case ProductCardAppearance.GreyWallPass:
      default:
        return (
          <>
            <div>{product.bankName}</div>
            <h5>{product.productName}</h5>
          </>
        );
    }
  };

  const getBrandingStyle = () => {
    switch (appearance) {
      case ProductCardAppearance.Tinkoff:
        return styles.tinkoffBranding;
      case ProductCardAppearance.GreyWallPass:
      default:
        return styles.defaultBranding;
    }
  };

  return (
    <div className={[styles.container, getGradient()].join(' ')} onClick={onClick}>
      <div className={[styles.branding, getBrandingStyle()].join(' ')}>{getBranding()}</div>
      <div className={styles.details}>
        <div className={styles.detailsLabel}>{language['VISITS_PER_PERIOD']}</div>
        <div className={styles.detailsValue}>{product.visits}</div>
        <div className={styles.detailsLabel}>{language['PERIOD_END']}</div>
        <div className={styles.detailsValue}>
          {format(lastDayOfMonth(new Date()), 'dd.MM.yyyy')}
        </div>
      </div>
      {showGreyWallPassLogo && <img src={logo} className={styles.gwpLogo} alt="" />}
    </div>
  );
};

export { ProductCardAppearance };
export default ProductCard;
