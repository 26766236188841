import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UserPhoneCreateModel,
  UserPhoneDeleteModel,
  UserPhoneModel,
  UserPhoneUpdateModel,
} from '../models';
import httpClient from '../api/http-client';
import { AppDispatch, RootState } from './store';
import { loadUserProfileAsync } from './profileDataSlice';

interface InitialState {
  userPhones: UserPhoneModel[];
}

export const userPhoneDataSlice = createSlice({
  name: 'userPhoneData',
  initialState: {
    userPhones: [],
  } as InitialState,
  reducers: {
    setUserPhones: (state, action: PayloadAction<UserPhoneModel[]>) => {
      state.userPhones = action.payload;
    },
  },
});

export const { setUserPhones } = userPhoneDataSlice.actions;

export const loadUserPhoneAsync = () => async (dispatch: AppDispatch) => {
  try {
    const response = await httpClient.get(`user/phone`);
    dispatch(setUserPhones(response.data));
  } catch (e: any) {
    console.log('loadUserPhoneAsync', e.message);
  } finally {
  }
};

export const createUserPhoneAsync =
  (data: UserPhoneCreateModel) => async (dispatch: AppDispatch) => {
    try {
      const response = await httpClient.post(`user/phone`, data);
      if (response.status === 200) {
        dispatch(loadUserProfileAsync());
      }
    } catch (e: any) {
      console.log('createUserPhoneAsync', e.message);
    } finally {
    }
  };

export const updateUserPhoneAsync =
  (data: UserPhoneUpdateModel) => async (dispatch: AppDispatch) => {
    try {
      const response = await httpClient.put(`user/phone`, data);
      if (response.status === 200) {
        dispatch(loadUserProfileAsync());
      }
    } catch (e: any) {
      console.log('updateUserPhoneAsync', e.message);
    } finally {
    }
  };

export const deleteUserPhoneAsync =
  (data: UserPhoneDeleteModel) => async (dispatch: AppDispatch) => {
    try {
      const response = await httpClient.delete(`user/phone?id=${data.id}`);
      if (response.status === 200) {
        dispatch(loadUserProfileAsync());
      }
    } catch (e: any) {
      console.log('deleteUserPhoneAsync', e.message);
    } finally {
    }
  };

export const selectUserPhones = (state: RootState) => state.userPhoneData.userPhones;

export default userPhoneDataSlice.reducer;
