import React, { useEffect, useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, IconButton, InputAdornment, InputBase } from '@mui/material';
import { ArrowBackIos, Visibility, VisibilityOff } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { loginUserAsync, selectLanguage } from '../../../redux/profileDataSlice';
import {
  registrationUserAsync,
  selectCodeId,
  selectLoadingRegistration,
  selectLoadingUpdatePassword,
  selectResetPassword,
  selectUserData,
  setResetPassword,
  updateUserPasswordAsync,
} from '../../../redux/registrationDataSlice';
import styles from './Password.module.css';

interface Interface {
  onSelectAuth: (type: string) => void;
}

const Password = ({ onSelectAuth }: Interface) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const loadingRegistration = useAppSelector(selectLoadingRegistration);
  const userData = useAppSelector(selectUserData);
  const resetPassword = useAppSelector(selectResetPassword);
  const loadingUpdatePassword = useAppSelector(selectLoadingUpdatePassword);
  const codeId = useAppSelector(selectCodeId);

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) =>
    event.preventDefault();

  const handleRegistration = async () => {
    if (!password.trim()) {
      alert(`${language['FILL_FIELDS']}: ${language['PASSWORD']}`);
    }
    if (resetPassword) {
      const phone = window.localStorage.getItem('gwp_username') || '';
      const result = await dispatch(updateUserPasswordAsync({ phone, password, codeId }));
      result && onSelectAuth('auth');
    } else {
      const result = await dispatch(
        registrationUserAsync({
          ...userData,
          password,
        })
      );

      if (result) {
        dispatch(loginUserAsync({ username: userData.phone, password }));
      } else {
        setError(language['REG_ERROR']);
      }
    }
  };

  useEffect(() => {
    return () => {
      resetPassword && dispatch(setResetPassword(false));
    };
  }, []);

  return (
    <div className={styles.root}>
      <div>
        <div onClick={() => onSelectAuth('')} className={styles.back}>
          <ArrowBackIos /> {language['GOBACK_LINK']}
        </div>
        <div className={styles.title}>{language['SET_PASSWORD']}</div>
        <InputBase
          autoFocus
          fullWidth
          type={showPassword ? 'text' : 'password'}
          className={styles.input}
          onChange={handleChange}
          placeholder={language['PASSWORD']}
          value={password}
          inputProps={{
            autoFocus: true,
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <PasswordStrengthBar
          password={password}
          scoreWords={[
            language['PASS_WRYWEAK'],
            language['PASS_WEAK'],
            language['PASS_OKAY'],
            language['PASS_GOOD'],
            language['PASS_STRONG'],
          ]}
          shortScoreWord={language['PASS_TOOSHORT']}
        />
        {error ? (
          <Alert variant="outlined" severity="error">
            {error}
          </Alert>
        ) : null}
      </div>
      <div>
        <LoadingButton
          loading={loadingRegistration || loadingUpdatePassword}
          variant="contained"
          classes={{ root: styles.button }}
          fullWidth
          onClick={handleRegistration}>
          {language['GO_BUTTON_1']}
        </LoadingButton>
      </div>
    </div>
  );
};

export default Password;
