import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { selectLanguage } from '../../../redux/profileDataSlice';
import { SUPPORT } from '../../../utils/config';
import { useAppSelector } from '../../../utils/hooks';
import styles from './SupportModal.module.css';

interface Interface {
  onClose: () => void;
}

const SupportModal = ({ onClose }: Interface) => {
  const history = useHistory();
  const language = useAppSelector(selectLanguage);

  useEffect(() => {
    const container = document.getElementById('container');

    if (container) {
      container.style.overflow = 'hidden';
    }

    return () => {
      if (container) {
        container.style.overflow = 'auto';
      }
    };
  }, []);

  const handleOpenSupportPage = () => history.push(SUPPORT);

  return (
    <>
      <div className={styles.backdrop} onClick={() => onClose()} />
      <div className={styles.modal}>
        <div className={styles.title}>{language['SUPPORT']}</div>
        <Button
          href="tel:88007003381"
          variant="text"
          fullWidth
          color="inherit"
          style={{ height: 48 }}>
          <div className={styles.button_inner}>
            <LocalPhoneOutlinedIcon className={styles.button_icon} />
            <div className={styles.button_content} style={{ marginLeft: 0 }}>
              <div className={styles.button_text}>{language['CALL_ITEM']}</div>
              <ArrowForwardIosIcon className={styles.button_arrow} />
            </div>
          </div>
        </Button>

        <Button
          onClick={handleOpenSupportPage}
          variant="text"
          fullWidth
          color="inherit"
          style={{ height: 48 }}>
          <div className={styles.button_inner}>
            <EmailOutlinedIcon className={styles.button_icon} />
            <div className={styles.button_content} style={{ marginLeft: 0 }}>
              <div className={styles.button_text}>{language['EMAIL_ITEM']}</div>
              <ArrowForwardIosIcon className={styles.button_arrow} />
            </div>
          </div>
        </Button>
      </div>
    </>
  );
};

export default SupportModal;
