import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, IconButton, InputAdornment, InputBase } from '@mui/material';
import { ArrowBackIos, Visibility, VisibilityOff } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import {
  loginUserAsync,
  selectLanguage,
  selectLoadingToken,
  selectTokenError,
  setTokenError,
} from '../../../redux/profileDataSlice';
import { sendCodeAsync, setResetPassword } from '../../../redux/registrationDataSlice';
import styles from './Auth.module.css';

interface Interface {
  onSelectAuth: (type: string) => void;
}

const Auth = ({ onSelectAuth }: Interface) => {
  const dispatch = useAppDispatch();

  const language = useAppSelector(selectLanguage);
  const loadingToken = useAppSelector(selectLoadingToken);
  const error = useAppSelector(selectTokenError);

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) =>
    event.preventDefault();

  const handleAuth = () => {
    const username = window.localStorage.getItem('gwp_username') || '';
    dispatch(loginUserAsync({ username, password }));
  };

  const handleResetPassword = () => {
    dispatch(setResetPassword(true));
    dispatch(sendCodeAsync(window.localStorage.getItem('gwp_username') || ''));
    onSelectAuth('verification');
  };

  const handleBack = () => {
    dispatch(setTokenError(''));
    onSelectAuth('manual');
  };

  return (
    <div className={styles.root}>
      <div>
        <div onClick={handleBack} className={styles.back}>
          <ArrowBackIos /> {language['GOBACK_LINK']}
        </div>
        <div className={styles.title}>{language['ENTER_PASSWORD']}</div>
        <InputBase
          autoFocus
          fullWidth
          type={showPassword ? 'text' : 'password'}
          className={styles.input}
          placeholder={language['PASSWORD']}
          value={password}
          error={!!error}
          onChange={handleChange}
          onKeyDown={(e) => e.key === 'Enter' && handleAuth()}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <div className={styles.reset} onClick={handleResetPassword}>
          {language['RESET_PASSWORD']}
        </div>
        {error ? (
          <Alert variant="outlined" severity="error">
            {language['WRONG_PASSWORD']}
          </Alert>
        ) : null}
      </div>
      <div>
        <LoadingButton
          loading={loadingToken}
          variant="contained"
          classes={{ root: styles.button }}
          disabled={!password}
          onClick={handleAuth}
          fullWidth>
          {language['ENTER_BUTTON']}
        </LoadingButton>
      </div>
    </div>
  );
};

export default Auth;
