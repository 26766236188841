import React from 'react';
import styles from './Header.module.css';
import logo from '../../static/logo_mini.svg';

interface Interface {
  title?: string;
  actions: React.ReactNode;
  className?: string;
}

const Header = (props: Interface) => {
  const { title, actions, className } = props;

  return (
    <div className={`${styles.header} ${className}`}>
      <img src={logo} alt="" />
      {title ? <div className={styles.title}>{title}</div> : null}
      {actions}
    </div>
  );
};

export default Header;
